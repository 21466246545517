import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import About from "../components/About";
import Services from "../components/Services";
import Faq from "../components/Faq";
import Contact from "../components/Contact";

function Home() {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <main className="main">
        <Hero />
        <About />
        <Services />
        <Faq />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default Home;
