export const alltransactions = [
  {
    id: 1,
    type: "Deposit",
    description: "Manual",
    date: "20-11-2024 4:55 AM",
    amount: "$500",
    status: "Pending",
    image: "https://via.placeholder.com/100", // Replace with actual image URL
  },
  {
    id: 2,
    type: "Withdrawal",
    description: "Automatic",
    date: "21-11-2024 3:30 PM",
    amount: "$200",
    status: "Pending",
    image: "https://via.placeholder.com/100", // Replace with actual image URL
  },
  {
    id: 3,
    type: "Deposit",
    description: "Automatic",
    date: "21-11-2024 3:30 PM",
    amount: "$200",
    status: "Pending",
    image: "https://via.placeholder.com/100", // Replace with actual image URL
  },
  {
    id: 4,
    type: "Withdrawal",
    description: "Automatic",
    date: "21-11-2024 3:30 PM",
    amount: "$200",
    status: "Pending",
    image: "https://via.placeholder.com/100", // Replace with actual image URL
  },
  {
    id: 5,
    type: "Deposit",
    description: "Automatic",
    date: "21-11-2024 3:30 PM",
    amount: "$200",
    status: "Pending",
    image: "https://via.placeholder.com/100", // Replace with actual image URL
  },
];
