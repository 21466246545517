import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";
import { useAuth } from "../context/AuthContext";
import { baseUrl } from "../utils/api";
import { tokenName } from "../utils";
import { createBrowserHistory } from "history";
import axios from "axios";

const Settings = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const { user } = useAuth(); // User fetched from context
  const [loading, setLoading] = useState(false);
  const history = createBrowserHistory();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State for form inputs
  const [identityType, setIdentityType] = useState("");
  const [identityNumber, setIdentityNumber] = useState("");
  const [identityProof, setIdentityProof] = useState("");
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    // Ensure user is not null or undefined
    if (user) {
      setUserInfo({
        identityType: user?.identityType || "",
        identityNumber: user?.identityNumber || "",
        identityProof: user?.identityProof || "",
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const token = localStorage.getItem(tokenName);
    try {
      await axios.put(`${baseUrl}/user/update`, userInfo, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      history.push("/settings"); // Navigate to the desired route
      history.go(0); // Refresh the page
      // setOpen(false);
    } catch (error) {
      setLoading(false);
      console.error(
        "Error updating user settings:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <Layout>
      {!user ? (
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            marginTop: "50px",
            color: "white",
            textAlign: "center",
            backgroundColor: "#21222d",
            minHeight: "100vh",
          }}
        >
          <Typography variant="h5" sx={{ color: "#9998a0" }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: 2,
            paddingTop: "50px",
            backgroundColor: "#21222d",
            minHeight: "100vh",
            color: "white",
          }}
        >
          <Box
            sx={{
              maxWidth: "700px",
              margin: "auto",
              my: 5,
            }}
          >
            <Card
              sx={{
                backgroundColor: "#171821",
                borderRadius: 3,
                boxShadow: 3,
                padding: 3,
                border: "1px solid #444",
              }}
            >
              <CardContent>
                {/* Title */}
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "white",
                    mb: 4,
                  }}
                >
                  Settings
                </Typography>

                {/* Identity Type Dropdown */}
                <TextField
                  select
                  label="Identity Type"
                  fullWidth
                  name="identityType" // Ensure this matches the state key
                  value={identityType}
                  onChange={handleInputChange}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  <MenuItem value="National ID">National ID</MenuItem>
                  <MenuItem value="Driving License">Driving License</MenuItem>
                  <MenuItem value="Passport">Passport</MenuItem>
                </TextField>

                {/* Identity Number Input */}
                <TextField
                  type="number" // Changed to a numeric input
                  label="Identity Number"
                  fullWidth
                  name="identityNumber" // Ensure this matches the state key
                  value={identityNumber}
                  onChange={handleInputChange}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />

                {/* Attach Identity Proof Input */}
                <TextField
                  type="file" // Changed to a file upload input
                  label="Attach Identity Proof"
                  fullWidth
                  name="identityProof" // Ensure this matches the state key
                  InputLabelProps={{
                    shrink: true, // Keeps the label visible above the input
                  }}
                  onChange={(e) => setIdentityProof(e.target.files[0])} // Update file state
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />

                {/* Verify Identity Button */}
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSaveChanges}
                  disabled={loading}
                  sx={{
                    color: "white",
                    fontWeight: "500",
                    fontSize: "1rem", // Increase the font size for better visibility
                    padding: 1,
                    height: "50px", // Set a fixed height for the button
                    backgroundColor: "#635bff",
                    "&:hover": {
                      backgroundColor: "#635bff",
                    },
                    transition: "all 0.3s ease", // Smooth transition for hover effect
                    textTransform: "none",
                    borderRadius: 50,
                  }}
                >
                  Verify Identity
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default Settings;
