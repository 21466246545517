import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  TextField, // Import TextField for the search input
} from "@mui/material";
import Layout from "../components/Layout";
import { useUser } from "../context/UserContext"; // Custom hook to access user context
import UserDetails from "../components/UserDetails";

const Users = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { users, loading } = useUser(); // Access users and loading state from context

  const [searchTerm, setSearchTerm] = useState(""); // State to store search term

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleOpenModal = (userId) => {
    setSelectedUserId(userId);
    setModalOpen(true);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: "50px",
          backgroundColor: "#21222d",
          minHeight: "100vh",
          color: "white",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            width: "100%",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              backgroundColor: "#171821",
            }}
          >
            <CardContent
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h4"
                component="div"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#fff" }}
              >
                Users
              </Typography>
              <Box
                sx={{
                  maxWidth: isSmallScreen ? "100%" : "500px", // Ensure it's full width on small screens
                  margin: "auto",
                }}
              >
                <Typography
                  variant="body1"
                  color="#9998a0"
                  sx={{ fontSize: 18 }}
                >
                  All registered users in the database
                </Typography>
              </Box>
              {/* Search Input */}
              <Box sx={{ marginTop: 3 }}>
                <TextField
                  label="Search by Name or Email"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    maxWidth: "500px",
                    margin: "auto",
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Users List */}
        <Box sx={{ my: 3 }}>
          {loading ? (
            <Typography
              variant="h6"
              sx={{ color: "#fff", textAlign: "center" }}
            >
              Loading users...
            </Typography>
          ) : filteredUsers.length === 0 ? (
            <Typography
              variant="h6"
              sx={{ color: "#fff", textAlign: "center" }}
            >
              No users found
            </Typography>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {filteredUsers.map((user) => (
                <Card
                  key={user.id}
                  sx={{
                    marginBottom: 2,
                    backgroundColor: "#171821",
                    borderRadius: 3,
                    boxShadow: 2,
                    padding: 2,
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row", // Stack on small screens
                    alignItems: "center",
                    overflow: "hidden", // Prevent overflow on cards
                  }}
                >
                  {/* Avatar / Profile Image */}
                  <Avatar
                    src={
                      user.profilePicture || "/assets/img/default-profile.png"
                    }
                    alt={user.name}
                    sx={{
                      width: 56,
                      height: 56,
                      marginBottom: isSmallScreen ? 2 : 0, // Add margin for small screens
                      marginRight: isSmallScreen ? 0 : 2,
                    }}
                  />
                  {/* User Details */}
                  <Box
                    sx={{
                      flexGrow: 1,
                      textAlign: isSmallScreen ? "center" : "left", // Align text for small screens
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        marginBottom: "4px",
                      }}
                    >
                      {user.firstName + " " + user.lastName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#9998a0", fontSize: 14 }}
                    >
                      {user.email}
                    </Typography>
                  </Box>
                  {/* Button to View User Details */}
                  <Button
                    variant="outlined"
                    // component="a"
                    // href={`/users/${user._id}`}
                    onClick={() => handleOpenModal(user._id)}
                    sx={{
                      color: "#fff",
                      borderColor: "#9998a0",
                      "&:hover": { borderColor: "#fff" },
                      marginTop: isSmallScreen ? 2 : 0, // Add margin for small screens
                    }}
                  >
                    View Details
                  </Button>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <UserDetails
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        userId={selectedUserId}
      />
    </Layout>
  );
};

export default Users;
