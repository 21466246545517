// import React, { createContext, useContext, useState, useEffect } from "react";
// import axios from "axios";
// import { tokenName } from "../utils";
// import { baseUrl } from "../utils/api";

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   // Updated fetchUserData function to handle loading and user state
//   const fetchUserData = async () => {
//     setLoading(true); // Start loading
//     const token = localStorage.getItem(tokenName);

//     if (token) {
//       try {
//         const response = await axios.get(`${baseUrl}/auth/user`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         const userData = response.data.user; // User data from response
//         setUser(userData); // Set user state to user data
//         setIsAuthenticated(true); // Set authentication status to true
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//         setUser(null); // Reset user on error
//         setIsAuthenticated(false); // Set authentication status to false
//       } finally {
//         setLoading(false); // Set loading to false after the try-catch block
//       }
//     } else {
//       setUser(null); // No token found, reset user
//       setIsAuthenticated(false); // Reset authentication status
//       setLoading(false); // Set loading to false
//     }
//   };

//   // Fetch all users (admin only)
//   const fetchUsers = async () => {
//     if (user && user.isAdmin === true) {
//       // Check if the logged-in user is an admin
//       setLoading(true);
//       try {
//         const response = await axios.get(`${baseUrl}/user/users`, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem(tokenName)}`, // Pass token in request
//           },
//         });
//         setUsers(response.data.users); // Assuming the API returns a list of users in `users` key
//       } catch (error) {
//         console.error("Error fetching users:", error);
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       console.log("Access Denied: You need to be an admin.");
//     }
//   };

//   // Fetch a specific user by ID
//   const fetchUserById = async (userId) => {
//     const token = localStorage.getItem(tokenName);
//     try {
//       const response = await axios.get(`${baseUrl}/user/${userId}`, {
//         headers: {
//           Authorization: `Bearer ${token}`, // Pass token in request
//         },
//       });
//       console.log(response);
//       return response.data; // Assuming the API returns the user data directly
//     } catch (error) {
//       console.error(`Error fetching user with ID ${userId}:`, error);
//       return null; // Return null if there's an error
//     }
//   };

//   useEffect(() => {
//     fetchUserData();
//   }, []);

//   // Fetch users when the component mounts
//   useEffect(() => {
//     fetchUsers();
//   }, [user]); // Re-run if the user state changes

//   return (
//     <UserContext.Provider
//       value={{
//         user,
//         isAuthenticated,
//         users,
//         loading,
//         fetchUserData,
//         fetchUserById, // Expose the new function in the context
//       }}
//     >
//       {children}
//     </UserContext.Provider>
//   );
// };

// export const useUser = () => useContext(UserContext);

import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { tokenName } from "../utils";
import { baseUrl } from "../utils/api";

// Create User Context
export const UserContext = createContext();

// User Provider Component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Current user state
  const [users, setUsers] = useState([]); // List of all users
  const [loading, setLoading] = useState(false); // Loading indicator
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication status

  // Fetch current user data
  const fetchUserData = async () => {
    setLoading(true); // Start loading
    const token = localStorage.getItem(tokenName);

    if (token) {
      try {
        const response = await axios.get(`${baseUrl}/auth/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const userData = response.data.user; // Extract user data
        setUser(userData);
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser(null);
        setIsAuthenticated(false);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      setUser(null);
      setIsAuthenticated(false);
      setLoading(false); // Stop loading
    }
  };

  // Fetch all users (Admin only)
  const fetchUsers = async () => {
    if (user?.isAdmin) {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(`${baseUrl}/user/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(tokenName)}`,
          },
        });

        setUsers(response.data.users); // Update users state
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      console.log("Access Denied: Admin privileges required.");
    }
  };

  // Fetch a specific user by ID
  const fetchUserById = async (userId) => {
    const token = localStorage.getItem(tokenName);

    if (!token) {
      console.error("Error: No token found. User not authenticated.");
      return null;
    }

    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data; // Return user data
    } catch (error) {
      console.error(`Error fetching user with ID ${userId}:`, error);
      return null; // Return null on error
    }
  };

  // Fetch user data on mount
  useEffect(() => {
    fetchUserData();
  }, []);

  // Fetch users whenever the user state changes (Admin check)
  useEffect(() => {
    if (user?.isAdmin) fetchUsers();
  }, [user]);

  // Context values
  const contextValue = {
    user,
    isAuthenticated,
    users,
    loading,
    fetchUserData,
    fetchUserById,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

// Custom hook to use User Context
export const useUser = () => useContext(UserContext);
