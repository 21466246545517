// import { createContext, useState, useContext } from "react";

// // Create Auth Context
// const AuthContext = createContext();

// // Auth Provider
// export const AuthProvider = ({ children }) => {
//   const [auth, setAuth] = useState({
//     isAuthenticated: false,
//     user: null,
//     token: null,
//   });

//   const login = (user, token) => {
//     setAuth({ isAuthenticated: true, user, token });
//   };

//   const logout = () => {
//     setAuth({ isAuthenticated: false, user: null, token: null });
//   };

//   return (
//     <AuthContext.Provider value={{ auth, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Custom Hook
// export const useAuth = () => useContext(AuthContext);

// import { createContext, useState, useContext } from "react";
// import { tokenName } from "../utils";

// // Create Auth Context
// const AuthContext = createContext();

// // Auth Provider
// export const AuthProvider = ({ children }) => {
//   const [auth, setAuth] = useState({
//     isAuthenticated: false,
//     user: null,
//     token: null,
//   });

//   // Handle login
//   const login = (user, token) => {
//     localStorage.setItem(tokenName, token); // Save token to localStorage
//     setAuth({ isAuthenticated: true, user, token });
//   };

//   // Handle logout
//   const logout = () => {
//     localStorage.removeItem(tokenName); // Remove token from localStorage
//     setAuth({ isAuthenticated: false, user: null, token: null });
//   };

//   return (
//     <AuthContext.Provider value={{ auth, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Custom Hook to access Auth Context
// export const useAuthContext = () => useContext(AuthContext);

// import { createContext, useState, useContext } from "react";
// import { tokenName } from "../utils";

// // Create Auth Context
// export const AuthContext = createContext(); // Export AuthContext directly

// // Auth Provider
// export const AuthProvider = ({ children }) => {
//   const [auth, setAuth] = useState({
//     isAuthenticated: false,
//     user: null,
//     token: null,
//   });

//   // Handle login
//   const login = (token) => {
//     localStorage.setItem(tokenName, token); // Save token to localStorage
//     setAuth({ isAuthenticated: true, token });
//   };

//   // Handle logout
//   const logout = () => {
//     localStorage.removeItem(tokenName); // Remove token from localStorage
//     setAuth({ isAuthenticated: false, token: null });
//   };

//   return (
//     <AuthContext.Provider value={{ auth, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Custom Hook to access Auth Context
// export const useAuthContext = () => useContext(AuthContext); // Use this hook to access the context

// import { createContext, useState, useContext, useEffect } from "react";
// import { tokenName } from "../utils";
// import axios from "axios";
// import { baseUrl } from "../utils/api";

// // Create Auth Context
// export const AuthContext = createContext(); // Export AuthContext directly

// // Auth Provider
// export const AuthProvider = ({ children }) => {
//   const [auth, setAuth] = useState({
//     isAuthenticated: false,
//     user: null,
//     token: null,
//   });

//   // Set user data after fetching user data based on token
//   const setUserData = (userData) => {
//     setAuth((prevAuth) => ({
//       ...prevAuth,
//       user: userData,
//     }));
//   };

//   // Handle login
//   const login = (token) => {
//     localStorage.setItem(tokenName, token); // Save token to localStorage
//     setAuth({ isAuthenticated: true, token });
//     fetchUserData(token); // Fetch user data after login
//   };

//   // Handle logout
//   const logout = () => {
//     localStorage.removeItem(tokenName); // Remove token from localStorage
//     setAuth({ isAuthenticated: false, user: null, token: null });
//   };

//   // Fetch user data based on the token
//   const fetchUserData = async (token) => {
//     try {
//       const response = await axios.get(`${baseUrl}/auth/user`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });

//       if (response.status === 200) {
//         setUserData(response.data.user); // Store user data in context
//       } else {
//         logout(); // Clear state if token is invalid
//       }
//     } catch (error) {
//       console.error("Error fetching user data:", error);
//       logout(); // Clear state on error
//     }
//   };

//   // Check if token exists and fetch user data when the app loads
//   useEffect(() => {
//     const token = localStorage.getItem(tokenName);
//     if (token) {
//       fetchUserData(token);
//     }
//   }, []);

//   return (
//     <AuthContext.Provider value={{ auth, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // Custom Hook to access Auth Context
// export const useAuthContext = () => useContext(AuthContext); // Use this hook to access the context

import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { tokenName } from "../utils";
// import { jwtDecode } from "jwt-decode";
import { baseUrl } from "../utils/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Fetch user data from token
  // const fetchUserData = () => {
  //   const token = localStorage.getItem(tokenName);
  //   if (token) {
  //     try {
  //       const decoded = jwtDecode(token);
  //       setUser(decoded);
  //       setIsAuthenticated(true);
  //     } catch {
  //       logout(); // Invalid token
  //     }
  //   } else {
  //     logout();
  //   }
  // };

  // Updated fetchUserData in AuthProvider
  // const fetchUserData = async () => {
  //   const token = localStorage.getItem(tokenName);

  //   if (token) {
  //     try {
  //       const response = await axios.get(`${baseUrl}/auth/user`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`, // Include token in Authorization header
  //         },
  //       });

  //       const user = response.data.user; // Get user data from response
  //       setUser(user);
  //       setIsAuthenticated(true);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //       logout(); // Handle error by logging out
  //     }
  //   } else {
  //     logout(); // No token found
  //   }
  // };

  // Updated fetchUserData function to handle loading and user state
  const fetchUserData = async () => {
    setLoading(true); // Start loading
    const token = localStorage.getItem(tokenName);

    if (token) {
      try {
        const response = await axios.get(`${baseUrl}/auth/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const userData = response.data.user; // User data from response
        setUser(userData); // Set user state to user data
        setIsAuthenticated(true); // Set authentication status to true
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser(null); // Reset user on error
        setIsAuthenticated(false); // Set authentication status to false
      } finally {
        setLoading(false); // Set loading to false after the try-catch block
      }
    } else {
      setUser(null); // No token found, reset user
      setIsAuthenticated(false); // Reset authentication status
      setLoading(false); // Set loading to false
    }
  };

  const register = async (userData) => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/auth/register`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        setUser(response.data.user); // Set the user after successful registration
        return response;
      }
    } catch (error) {
      console.error("Error during registration:", error);
      throw error; // Throw error to be handled by the Register page
    } finally {
      setLoading(false);
    }
  };

  // Login function
  const login = async (email, password) => {
    const url = `${baseUrl}/auth/login`;
    const requestBody = { email, password };
    const response = await axios.post(url, requestBody, {
      headers: { "Content-Type": "application/json" },
    });
    const { token } = response.data;
    localStorage.setItem(tokenName, token);
    fetchUserData();
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem(tokenName);
    setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        register,
        loading,
        setLoading,
        login,
        logout,
        fetchUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
