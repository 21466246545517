// import React, { useState } from "react";

// function Faq() {
//   // Set activeIndex to 0 to open the first FAQ by default
//   const [activeIndex, setActiveIndex] = useState(0);

//   const toggleFAQ = (index) => {
//     setActiveIndex(activeIndex === index ? null : index); // Toggle open/close
//   };

//   const faqs = [
//     {
//       question: "What is an Quantum Financial System?",
//       answer:
//         "An Quantum Financial System is a decentralized platform that enables anyone to participate in financial services, such as payments, savings, and lending. By using open protocols and blockchain technology, it empowers individuals with greater control over their finances while enhancing transparency and reducing reliance on traditional intermediaries like banks.",
//     },
//     {
//       question: "How do I benefit from using an Quantum Financial System?",
//       answer:
//         "The main benefits include greater financial inclusion, transparency, and control over your personal finances. You can access a wide range of financial services with lower fees, faster transactions, and enhanced privacy compared to traditional systems. Moreover, Quantum Financial Systems often allow for peer-to-peer transactions without the need for a central authority.",
//     },
//     {
//       question: "Is my data secure in an Quantum Financial System?",
//       answer:
//         "Yes, security is a core principle of any Quantum Financial System. Transactions are encrypted and stored on secure, immutable ledgers like blockchain, making it nearly impossible for unauthorized parties to alter or tamper with your data. Additionally, decentralized models reduce the risk of single points of failure and hacking attempts compared to centralized systems.",
//     },
//     {
//       question: "How can I get started with the Quantum Financial System?",
//       answer:
//         "Getting started is simple. You can create an account with a participating platform, connect your digital wallet, and start exploring financial services like sending payments, earning interest, or investing. Many platforms also provide educational resources to help you understand the technology behind open finance and how to make the most of it.",
//     },
//     {
//       question:
//         "What types of financial services can I access in an Quantum Financial System?",
//       answer:
//         "Quantum Financial Systems provide a wide range of services, including decentralized lending and borrowing, remittances, payments, investments, and insurance. You can also access services like decentralized exchanges (DEXs), yield farming, and staking, which are often not available through traditional banking.",
//     },
//     {
//       question: "Are there fees associated with Quantum Financial Systems?",
//       answer:
//         "Fees in an Quantum Financial System are typically lower than traditional banking fees, as there are no intermediaries. However, there may be small network or transaction fees depending on the platform and the blockchain used. These fees are generally transparent and are used to maintain the system's security and operation.",
//     },
//   ];

//   return (
//     <section className="faq-9 faq section light-background" id="faq">
//       <div className="container">
//         <div className="row">
//           <div className="col-lg-5" data-aos="fade-up">
//             <h2 className="faq-title">Got Questions? Explore Our FAQs</h2>
//             <p className="faq-description text-gray">
//               We understand that navigating the world of finance can be
//               overwhelming. Check out the answers to the most common questions
//               about our Quantum Financial System.
//             </p>
//           </div>

//           <div className="col-lg-7" data-aos="fade-up" data-aos-delay="300">
//             <div className="faq-container">
//               {faqs.map((faq, index) => (
//                 <div
//                   key={index}
//                   className={`faq-item ${
//                     activeIndex === index ? "faq-active" : ""
//                   }`}
//                 >
//                   <h3 onClick={() => toggleFAQ(index)}>{faq.question}</h3>
//                   <div
//                     className={`faq-content ${
//                       activeIndex === index ? "show" : ""
//                     }`}
//                   >
//                     <p>{faq.answer}</p>
//                   </div>
//                   <i
//                     className={`faq-toggle bi ${
//                       activeIndex === index
//                         ? "bi-chevron-right"
//                         : "bi-chevron-up"
//                     }`}
//                     onClick={() => toggleFAQ(index)}
//                   ></i>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Faq;

import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../theme";

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is a Quantum Financial System?",
      answer:
        "A Quantum Financial System is a decentralized platform that enables anyone to participate in financial services, such as payments, savings, and lending. By using open protocols and blockchain technology, it empowers individuals with greater control over their finances while enhancing transparency and reducing reliance on traditional intermediaries like banks.",
    },
    {
      question: "How do I benefit from using a Quantum Financial System?",
      answer:
        "The main benefits include greater financial inclusion, transparency, and control over your personal finances. You can access a wide range of financial services with lower fees, faster transactions, and enhanced privacy compared to traditional systems. Moreover, Quantum Financial Systems often allow for peer-to-peer transactions without the need for a central authority.",
    },
    {
      question: "Is my data secure in a Quantum Financial System?",
      answer:
        "Yes, security is a core principle of any Quantum Financial System. Transactions are encrypted and stored on secure, immutable ledgers like blockchain, making it nearly impossible for unauthorized parties to alter or tamper with your data. Additionally, decentralized models reduce the risk of single points of failure and hacking attempts compared to centralized systems.",
    },
    {
      question: "How can I get started with the Quantum Financial System?",
      answer:
        "Getting started is simple. You can create an account with a participating platform, connect your digital wallet, and start exploring financial services like sending payments, earning interest, or investing. Many platforms also provide educational resources to help you understand the technology behind open finance and how to make the most of it.",
    },
    {
      question:
        "What types of financial services can I access in a Quantum Financial System?",
      answer:
        "Quantum Financial Systems provide a wide range of services, including decentralized lending and borrowing, remittances, payments, investments, and insurance. You can also access services like decentralized exchanges (DEXs), yield farming, and staking, which are often not available through traditional banking.",
    },
    {
      question: "Are there fees associated with Quantum Financial Systems?",
      answer:
        "Fees in a Quantum Financial System are typically lower than traditional banking fees, as there are no intermediaries. However, there may be small network or transaction fees depending on the platform and the blockchain used. These fees are generally transparent and are used to maintain the system's security and operation.",
    },
  ];

  return (
    <Box
      id="faq"
      style={{
        padding: "40px 0",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            color="#fff"
            gutterBottom
            sx={{
              fontWeight: "500",
              fontFamily: "Faculty Glyphic, sans-serif",
            }}
          >
            Got Questions? Explore Our FAQs
          </Typography>
          <Typography variant="body1" color="#9998a0" align="center">
            We understand that navigating the world of finance can be
            overwhelming. Check out the answers to the most common questions
            about our Quantum Financial System.
          </Typography>
        </Box>

        {/* FAQ List */}
        <Box>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={activeIndex === index}
              onChange={() => toggleFAQ(index)}
              sx={{
                boxShadow: 3,
                mb: 2,
                borderRadius: "8px",
                "&:last-child": {
                  marginBottom: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`faq-header-${index}`}
              >
                <Typography variant="h6" color="#4aa5f0">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: { xs: "1rem", sm: "1rem" },
                  }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default Faq;
