import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import JivoChat from "./components/JivoChat";

import PrivateRoute from "./routes/PrivateRoute";
import ProtectedRoute from "./routes/ProtectedRoute";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import Wallets from "./pages/Wallets";
import Virtualcard from "./pages/Virtualcard";
import Transactions from "./pages/Transactions";
import Investment from "./pages/Investment";
import Exchange from "./pages/Exchange";
import Withdraw from "./pages/Withdraw";
import Deposit from "./pages/Deposit";
import SendMoney from "./pages/SendMoney";
import RequestMoney from "./pages/RequestMoney";
import Users from "./pages/Users";
import UserDetails from "./pages/UserDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<ProtectedRoute element={Login} />} />
        <Route
          path="/register"
          element={<ProtectedRoute element={Register} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={Dashboard} />}
        />
        <Route path="/profile" element={<PrivateRoute element={Profile} />} />
        <Route path="/settings" element={<PrivateRoute element={Settings} />} />
        <Route path="/wallets" element={<PrivateRoute element={Wallets} />} />
        <Route
          path="/virtualcard"
          element={<PrivateRoute element={Virtualcard} />}
        />
        <Route
          path="/transactions"
          element={<PrivateRoute element={Transactions} />}
        />
        <Route
          path="/investment"
          element={<PrivateRoute element={Investment} />}
        />
        <Route path="/exchange" element={<PrivateRoute element={Exchange} />} />
        <Route path="/withdraw" element={<PrivateRoute element={Withdraw} />} />
        <Route path="/deposit" element={<PrivateRoute element={Deposit} />} />
        <Route
          path="/send-money"
          element={<PrivateRoute element={SendMoney} />}
        />
        <Route
          path="/request-money"
          element={<PrivateRoute element={RequestMoney} />}
        />
        <Route path="/users" element={<PrivateRoute element={Users} />} />
        <Route path="/users/:userId" element={<UserDetails />} />
      </Routes>
      <ToastContainer />
      {/* <JivoChat /> */}
    </Router>
  );
}

export default App;
