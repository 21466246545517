import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";

const Investment = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State for form inputs
  const [investmentPlan, setInvestmentPlan] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");

  // Handle input changes
  const handlePlanChange = (event) => setInvestmentPlan(event.target.value);
  const handleAmountChange = (event) => setInvestmentAmount(event.target.value);

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: "50px",
          backgroundColor: "#21222d",
          minHeight: "100vh",
          color: "white",
        }}
      >
        {/* Investment Form Card */}
        <Box
          sx={{
            maxWidth: "800px",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              backgroundColor: "#171821",
              borderRadius: 3,
              boxShadow: 3,
              padding: 3,
            }}
          >
            <CardContent>
              {/* Title */}
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Invest Now
              </Typography>

              {/* Step Indicator */}
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: "#635bff",
                  marginBottom: 2,
                }}
              >
                Step: 1 of 2
              </Typography>

              {/* Instruction Text */}
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: "#9998a0",
                  fontSize: "14px",
                  marginBottom: 2,
                }}
              >
                PLEASE SELECT A PLAN BELOW AND ENTER AN AMOUNT TO INVEST WHERE
                APPLICABLE TO BEGIN INVESTMENT
              </Typography>

              {/* Investment Plan Dropdown */}
              <TextField
                select
                label="Investment Plan"
                fullWidth
                value={investmentPlan}
                onChange={handlePlanChange}
                sx={{
                  marginBottom: 3,
                  "& .MuiInputLabel-root": { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    color: "white",
                    "& fieldset": {
                      borderColor: "#444",
                    },
                    "&:hover fieldset": {
                      borderColor: "#888",
                    },
                  },
                }}
              >
                <MenuItem value="plan1">Plan 1</MenuItem>
                <MenuItem value="plan2">Plan 2</MenuItem>
                <MenuItem value="plan3">Plan 3</MenuItem>
              </TextField>
              <Box
                sx={{
                  display: "flex", // Use flex layout
                  gap: 2, // Add space between the items
                  marginBottom: 5, // Add margin below the container
                }}
              >
                {/* Investment Currency Input */}
                <TextField
                  select
                  label="Currency"
                  fullWidth
                  value={investmentPlan}
                  onChange={handlePlanChange}
                  sx={{
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="BTC">BTC</MenuItem>
                  <MenuItem value="LTC">LTC</MenuItem>
                </TextField>

                {/* Investment Amount Input */}
                <TextField
                  label="Amount"
                  type="number"
                  fullWidth
                  value={investmentAmount}
                  onChange={handleAmountChange}
                  sx={{
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />
              </Box>

              {/* Proceed Button */}
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#635bff",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 16,
                  padding: 1,
                  "&:hover": {
                    backgroundColor: "#635bff",
                  },
                  textTransform: "none",
                }}
              >
                Proceed
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Layout>
  );
};

export default Investment;
