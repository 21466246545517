import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  IconButton,
  Snackbar,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Icon for the copy button
import { useUser } from "../context/UserContext";
import { useWallet } from "../context/WalletContext";

const UserDetails = ({ open, onClose, userId }) => {
  const [userDetails, setUserDetails] = useState(null);
  const { fetchUserById } = useUser();
  const { fetchUserIdWallet, wallets } = useWallet();
  const [copySuccess, setCopySuccess] = useState(""); // For snackbar feedback
  const [expanded, setExpanded] = useState(false); // State to manage wallet section expansion

  useEffect(() => {
    if (userId) {
      const getUserDetails = async () => {
        const userData = await fetchUserById(userId);
        setUserDetails(userData);
      };
      getUserDetails();
      fetchUserIdWallet(userId); // Fetch wallets for the given userId
    }
  }, [userId, fetchUserById, fetchUserIdWallet]);

  const handleCopy = (passphrase) => {
    // Copy passphrase to clipboard
    navigator.clipboard.writeText(passphrase).then(() => {
      setCopySuccess("Passphrase copied to clipboard!");
      setTimeout(() => setCopySuccess(""), 3000); // Reset the success message after 3 seconds
    });
  };

  const shortenPassphrase = (passphrase) => {
    // Shorten passphrase to the first 8 characters and add ellipsis
    return passphrase.length > 8 ? passphrase.slice(0, 8) + "..." : passphrase;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>User Details</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            padding: 3,
            backgroundColor: "#21222d",
            color: "white",
          }}
        >
          {userDetails ? (
            <Card
              sx={{
                backgroundColor: "#171821",
                borderRadius: 3,
                boxShadow: 2,
              }}
            >
              <CardContent>
                <Avatar
                  src={
                    userDetails.profilePicture ||
                    "/assets/img/default-profile.png"
                  }
                  alt={userDetails.firstName}
                  sx={{ width: 100, height: 100, marginBottom: 2 }}
                />
                <Typography
                  variant="h4"
                  sx={{ color: "#fff", fontWeight: "bold" }}
                >
                  {userDetails.firstName} {userDetails.lastName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#9998a0", marginTop: 1 }}
                >
                  Email: {userDetails.email}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#9998a0", marginTop: 1 }}
                >
                  Phone: {userDetails.phone}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#9998a0", marginTop: 1 }}
                >
                  IP Address: {userDetails.ipAddress || "Not available"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#9998a0", marginTop: 1 }}
                >
                  Admin Status: {userDetails.isAdmin ? "Yes" : "No"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#9998a0", marginTop: 1 }}
                >
                  Verified: {userDetails.isVerified ? "Yes" : "No"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#9998a0", marginTop: 2 }}
                >
                  Bio: {userDetails.bio || "No bio available"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#9998a0", marginTop: 2 }}
                >
                  Registered On:{" "}
                  {new Date(userDetails.createdAt).toLocaleString()}
                </Typography>

                {/* Display Wallets */}
                <Typography variant="h6" sx={{ color: "#fff", marginTop: 3 }}>
                  Wallets:
                </Typography>

                {/* Collapse component to toggle wallet section */}
                <Collapse in={expanded}>
                  {wallets && wallets.length > 0 ? (
                    wallets.map((wallet, index) => (
                      <Card
                        key={index}
                        sx={{
                          backgroundColor: "#23242d",
                          borderRadius: 2,
                          marginTop: 2,
                          padding: 2,
                        }}
                      >
                        <Typography variant="body1" sx={{ color: "#fff" }}>
                          Wallet Name: {wallet.walletName}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#9998a0" }}>
                          Passphrase: {shortenPassphrase(wallet.passPhrase)}
                          <IconButton
                            sx={{ marginLeft: 1 }}
                            onClick={() => handleCopy(wallet.passPhrase)}
                          >
                            <ContentCopyIcon sx={{ color: "#9998a0" }} />
                          </IconButton>
                        </Typography>
                      </Card>
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{ color: "#9998a0", marginTop: 2 }}
                    >
                      No wallets available.
                    </Typography>
                  )}
                </Collapse>

                {/* Button to toggle wallet section */}
                <Button
                  sx={{
                    marginTop: 2,
                    color: "#fff",
                    borderColor: "#9998a0",
                    "&:hover": { borderColor: "#fff" },
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? "Hide Wallets" : "Show Wallets"}
                </Button>
              </CardContent>
            </Card>
          ) : (
            <Typography variant="h6" sx={{ color: "#fff" }}>
              User not found.
            </Typography>
          )}
        </Box>
      </DialogContent>

      {/* Snackbar for copy success message */}
      {copySuccess && (
        <Snackbar
          open={!!copySuccess}
          autoHideDuration={3000}
          message={copySuccess}
        />
      )}
    </Dialog>
  );
};

export default UserDetails;
