// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   useMediaQuery,
//   useTheme,
//   Card,
//   CardContent,
//   Typography,
//   Button,
//   TextField,
//   MenuItem,
// } from "@mui/material";
// import Layout from "../components/Layout";
// import { useTransaction } from "../context/TransactionContext";

// const SendMoney = () => {
//   useEffect(() => {
//     // Scroll to the top of the page when the component loads
//     window.scrollTo(0, 0);
//   }, []);

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

//   // State for form inputs
//   const [recipient, setRecipient] = useState("");
//   const [investmentPlan, setInvestmentPlan] = useState("");
//   const [investmentAmount, setInvestmentAmount] = useState("");

//   // Handle input changes
//   const handleRecipientChange = (event) => setRecipient(event.target.value);
//   const handlePlanChange = (event) => setInvestmentPlan(event.target.value);
//   const handleAmountChange = (event) => setInvestmentAmount(event.target.value);

//   const { depositFunds, loading, error } = useTransaction();
//   const [amount, setAmount] = useState("");
//   const [currency, setCurrency] = useState("USD");

//   const handleSend = async () => {
//     const result = await depositFunds(Number(amount), currency);
//     if (result) alert(result.message); // Show success message
//   };

//   return (
//     <Layout>
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           padding: 2,
//           paddingTop: "50px",
//           backgroundColor: "#21222d",
//           minHeight: "100vh",
//           color: "white",
//         }}
//       >
//         {/* Investment Form Card */}
//         <Box
//           sx={{
//             maxWidth: "800px",
//             margin: "auto",
//             my: 5,
//           }}
//         >
//           <Card
//             sx={{
//               backgroundColor: "#171821",
//               borderRadius: 3,
//               boxShadow: 3,
//               padding: 3,
//             }}
//           >
//             <CardContent>
//               {/* Title */}
//               <Typography
//                 variant="h4"
//                 sx={{
//                   textAlign: "center",
//                   fontWeight: "bold",
//                   color: "white",
//                 }}
//               >
//                 Send Money
//               </Typography>

//               {/* Step Indicator */}
//               <Typography
//                 variant="body2"
//                 sx={{
//                   textAlign: "center",
//                   color: "#635bff",
//                   marginBottom: 2,
//                 }}
//               >
//                 Step: 1 of 2
//               </Typography>

//               {/* Instruction Text */}
//               <Typography
//                 variant="body2"
//                 sx={{
//                   textAlign: "center",
//                   color: "#9998a0",
//                   fontSize: "14px",
//                   marginBottom: 2,
//                 }}
//               >
//                 Enter your recipients email address & then add an amount with
//                 currency. You can also provide a note for reference.
//               </Typography>

//               {/* Recipient Input */}
//               <TextField
//                 label="Recipient"
//                 fullWidth
//                 value={recipient}
//                 onChange={handleRecipientChange}
//                 sx={{
//                   marginBottom: 3,
//                   "& .MuiInputLabel-root": { color: "white" },
//                   "& .MuiOutlinedInput-root": {
//                     color: "white",
//                     "& fieldset": {
//                       borderColor: "#444",
//                     },
//                     "&:hover fieldset": {
//                       borderColor: "#888",
//                     },
//                   },
//                 }}
//               />

//               <Box
//                 sx={{
//                   display: "flex", // Use flex layout
//                   gap: 2, // Add space between the items
//                   marginBottom: 3, // Add margin below the container
//                 }}
//               >
//                 {/* Investment Currency Input */}
//                 <TextField
//                   select
//                   label="Currency"
//                   fullWidth
//                   value={investmentPlan}
//                   onChange={handlePlanChange}
//                   sx={{
//                     "& .MuiInputLabel-root": { color: "white" },
//                     "& .MuiOutlinedInput-root": {
//                       color: "white",
//                       "& fieldset": {
//                         borderColor: "#444",
//                       },
//                       "&:hover fieldset": {
//                         borderColor: "#888",
//                       },
//                     },
//                   }}
//                 >
//                   <MenuItem value="USD">USD</MenuItem>
//                   <MenuItem value="BTC">BTC</MenuItem>
//                   <MenuItem value="LTC">LTC</MenuItem>
//                 </TextField>

//                 {/* Investment Amount Input */}
//                 <TextField
//                   label="Amount"
//                   type="number"
//                   fullWidth
//                   value={investmentAmount}
//                   onChange={handleAmountChange}
//                   sx={{
//                     "& .MuiInputLabel-root": { color: "white" },
//                     "& .MuiOutlinedInput-root": {
//                       color: "white",
//                       "& fieldset": {
//                         borderColor: "#444",
//                       },
//                       "&:hover fieldset": {
//                         borderColor: "#888",
//                       },
//                     },
//                   }}
//                 />
//               </Box>

//               {/* Note Input */}
//               <TextField
//                 label="Note"
//                 multiline
//                 rows={4}
//                 fullWidth
//                 sx={{
//                   marginBottom: 5,
//                   "& .MuiInputLabel-root": { color: "white" },
//                   "& .MuiOutlinedInput-root": {
//                     color: "white",
//                     "& fieldset": {
//                       borderColor: "#444",
//                     },
//                     "&:hover fieldset": {
//                       borderColor: "#888",
//                     },
//                   },
//                 }}
//               />

//               {/* Proceed Button */}
//               <Button
//                 variant="contained"
//                 fullWidth
//                 sx={{
//                   backgroundColor: "#635bff",
//                   color: "white",
//                   fontWeight: "bold",
//                   fontSize: 16,
//                   padding: 1,
//                   "&:hover": {
//                     backgroundColor: "#635bff",
//                   },
//                   textTransform: "none",
//                 }}
//               >
//                 Proceed
//               </Button>
//             </CardContent>
//           </Card>
//         </Box>
//       </Box>
//     </Layout>
//   );
// };

// export default SendMoney;

import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";
import { currencies } from "../utils/currencies-data";

const SendMoney = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State for form inputs
  const [step, setStep] = useState(1);
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [note, setNote] = useState("");

  const handleSend = async () => {};

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: "50px",
          backgroundColor: "#21222d",
          minHeight: "100vh",
          color: "white",
        }}
      >
        {/* Investment Form Card */}
        <Box
          sx={{
            maxWidth: "800px",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              backgroundColor: "#171821",
              borderRadius: 3,
              boxShadow: 3,
              padding: 3,
            }}
          >
            <CardContent>
              {/* Title */}
              <Typography
                variant="h4"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Send Money
              </Typography>

              {/* Step Indicator */}
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: "#635bff",
                  my: 2,
                }}
              >
                Step: {step} of 3
              </Typography>

              {/* Instruction Text */}
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  color: "#9998a0",
                  fontSize: "14px",
                  marginBottom: 5,
                }}
              >
                Enter your payer email address then add an amount with currency
                to request payment. You may add a note for reference.
              </Typography>

              {/* Recipient Input */}
              <TextField
                label="Recipient"
                fullWidth
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
                sx={{
                  marginBottom: 3,
                  "& .MuiInputLabel-root": { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    color: "white",
                    "& fieldset": {
                      borderColor: "#444",
                    },
                    "&:hover fieldset": {
                      borderColor: "#888",
                    },
                  },
                }}
              />

              <Box
                sx={{
                  display: "flex", // Use flex layout
                  gap: 2, // Add space between the items
                  marginBottom: 3, // Add margin below the container
                }}
              >
                {/* Investment Currency Input */}
                <TextField
                  select
                  label="Currency"
                  fullWidth
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  sx={{
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Investment Amount Input */}
                <TextField
                  label="Amount"
                  type="number"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={{
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />
              </Box>

              {/* Note Input */}
              <TextField
                label="Note"
                multiline
                rows={4}
                fullWidth
                value={note}
                onChange={(e) => setNote(e.target.value)}
                sx={{
                  // marginBottom: 5,
                  "& .MuiInputLabel-root": { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    color: "white",
                    "& fieldset": {
                      borderColor: "#444",
                    },
                    "&:hover fieldset": {
                      borderColor: "#888",
                    },
                  },
                }}
              />

              {/* Proceed Button */}
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#635bff",
                  color: "white",
                  fontWeight: "500",
                  fontSize: 16,
                  // padding: 1,
                  height: 50,
                  borderRadius: 50,
                  mt: 5,
                  "&:hover": {
                    backgroundColor: "#635bff",
                  },
                  textTransform: "none",
                }}
              >
                Proceed
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Layout>
  );
};

export default SendMoney;
