import React, { createContext, useState, useContext } from "react";
import { baseUrl } from "../utils/api";
import { tokenName } from "../utils";
import axios from "axios";

// Create the card Context
const CardContext = createContext();

// card Context Provider component
export const CardProvider = ({ children }) => {
  const [card, setCard] = useState(null);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);

  // Add card function
  const addCard = async (cardType, cardCurrency, prefundAmount) => {
    const url = `${baseUrl}/card/add`;
    const requestBody = {
      cardType,
      cardCurrency,
      prefundAmount,
    };

    const token = localStorage.getItem(tokenName);

    try {
      await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Error adding card:", error);
    }
  };

  // Fetch User cards function
  const fetchUserIdCard = async (userId) => {
    const url = `${baseUrl}/card/${userId}`; // Adjust URL if needed to match backend route
    const token = localStorage.getItem(tokenName);

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Store fetched cards in the state
      setCards(response.data.cards);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  return (
    <CardContext.Provider
      value={{
        addCard,
        fetchUserIdCard, // Expose fetchUserIdCard function
        cards, // Provide current cards to components
        card,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};

export const useCard = () => useContext(CardContext);
