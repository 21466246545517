import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
} from "@mui/material";
// import { useNavigate } from "react-router-dom"; // Use navigation for routing
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Layout from "../components/Layout";
import currenciesData from "../data/currencies-data";
import { useAuth } from "../context/AuthContext";
// import { useWallet } from "../context/WalletContextx";
import WalletSelectionModal from "../components/WalletSelectionModal";

const Dashboard = ({ isSmallScreen }) => {
  // useEffect(() => {
  //   // Scroll to the top of the page when the component loads
  //   window.scrollTo(0, 0);
  // }, []);

  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const navigate = useNavigate();
  const { user, loading } = useAuth();
  // const { walletAddress, connectWallet, disconnectWallet, isConnected } =
  //   useWallet();

  const [modalOpen, setModalOpen] = useState(false);

  // Modal handlers
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          marginTop: "50px", // Adjust for Navbar height
          backgroundColor: "#21222d", // New background color
          color: "white", // Text color to ensure visibility against dark background
        }}
      >
        {/* Title, Image, and Description with Buttons on the opposite side */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center", // Ensure both the text and buttons are aligned
            marginBottom: 4,
            marginTop: 4, // Added top margin of 4 (32px)
            flexDirection: { xs: "column", sm: "row" }, // Stack vertically on extra small screens, row on small and above
            backgroundColor: "#171821", // Background color for this section
            padding: 2, // Add some padding to the container
            borderRadius: 3, // Optional: rounded corners for the section
          }}
        >
          <Box
            sx={{
              p: 3,
              display: "flex",
              alignItems: "center",
              marginBottom: { xs: 2, sm: 0 }, // Add bottom margin on small screens only
              width: { xs: "100%", sm: "auto" }, // Full width on small screens, auto on larger screens
            }}
          >
            {/* Image next to the title */}
            <Box>
              <Typography variant="body1" sx={{ color: "#9998a0" }}>
                Connect and synchronize your digital wallets with QFS now for
                maximum cybersecurity and seamless funding or transfer of your
                assets using the Quantum Finance Credit Card.
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#635bff",
              color: "white",
              padding: "6px 12px", // Adjust padding to reduce button height
              fontSize: "14px", // Smaller text size
              width: "auto", // Auto width for buttons
              minWidth: "120px", // Minimum width for buttons to maintain size consistency
              textTransform: "none", // Ensures uppercase text
              marginTop: { xs: 2, sm: 0 }, // Add top margin on small screens only
            }}
            onClick={openModal} // Open modal for wallet selection
          >
            Connect Wallet
          </Button>
        </Box>

        {/* Currencies Cards List */}
        <Grid container spacing={3}>
          {user?.currencies?.map((currency) => (
            <Grid item xs={6} sm={6} md={4} key={currency.id}>
              <Link
                to="/wallets"
                style={{
                  textDecoration: "none", // Remove underline
                  display: "block", // Ensure the Link wraps the entire Card
                }}
              >
                <Card
                  sx={{
                    padding: 3,
                    borderRadius: 3,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#171821", // Darker background for each card
                    color: "white", // White text for better contrast
                  }}
                >
                  {/* Title and Image Row */}
                  <CardContent
                    sx={{
                      p: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      paddingBottom: 1, // Remove extra padding
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ marginRight: 2, fontSize: "15px" }}
                      >
                        {currency.name}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%", // Circular image
                      }}
                      image={currency.image}
                      alt={currency.title}
                    />
                  </CardContent>
                  {/* Price and Subtitle Row */}
                  <CardContent
                    sx={{
                      p: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "end",
                      paddingTop: 0, // Remove extra padding
                    }}
                    style={{
                      padding: 0,
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        marginRight: 2,
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      $ {currency.balance}
                    </Typography>
                    <Typography variant="body2" color="#2AAA5E">
                      {currency.subtitle}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            width: "100%",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              p: 2,
              boxShadow: 3,
              borderRadius: 3,
              backgroundColor: "#171821",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#fff" }}
              >
                QFS Goal
              </Typography>
              <Typography variant="body1" color="#fff">
                The QFS releases funds to improve the well-being and quality of
                life for people in need, often in areas affected by poverty,
                conflict, or natural disasters. These projects can involve
                providing access to healthcare, clean water, education, or other
                essential resources.
              </Typography>
            </CardContent>
          </Card>
        </Box>

        <Box
          sx={{
            width: "100%",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              padding: 2,
              boxShadow: 3,
              borderRadius: 3,
              backgroundColor: "#171821",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#fff" }}
              >
                Submit Humanitarian Project.
              </Typography>
              <Typography variant="body1" color="#fff" sx={{ marginBottom: 3 }}>
                Upload Humanitarian Document. Please click the button below to
                upload your file.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#635bff",
                  "&:hover": {
                    backgroundColor: "#635bff",
                  },
                  textTransform: "none", // Ensures uppercase text
                }}
              >
                Upload File
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Wallet Selection Modal */}
        <WalletSelectionModal open={modalOpen} onClose={closeModal} />
      </Box>
    </Layout>
  );
};

export default Dashboard;
