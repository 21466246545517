import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react"; // Import Icon from Iconify
import { sidebarMenu } from "../data/sidebarMenu";
import { useAuth } from "../context/AuthContext";
// import PropTypes from "prop-types";

const Sidebar = ({ isOpen, toggleSidebar, isSmallScreen }) => {
  // console.log("isOpen", isOpen);
  // console.log("toggleSidebar", toggleSidebar);

  const { user } = useAuth(); // Get the user data from AuthContext
  const location = useLocation(); // Get the current location/path

  // console.log(user);

  // Function to check if the menu item is active
  const isActive = (path) => location.pathname === path;

  // Conditionally add the "Users" tab if the user is an admin
  const sidebarItems =
    user?.isAdmin === true
      ? [
          ...sidebarMenu, // Include the original sidebar menu
          {
            id: 11,
            path: "/users",
            title: "Users",
            icon: "mingcute:user-1-fill",
          },
        ]
      : sidebarMenu; // Show regular sidebar if not admin

  return (
    <Drawer
      variant={isSmallScreen ? "temporary" : "permanent"}
      open={isOpen}
      onClose={toggleSidebar}
      sx={{
        width: 240,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          backgroundColor: "#171821", // Apply the background color to the sidebar
        },
      }}
    >
      {/* <Box
        role="presentation"
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
        sx={{
          paddingTop: isSmallScreen ? "60px" : 0, // Add padding-top on small screens
        }}
      > */}
      <Box
        role="presentation"
        onClick={toggleSidebar}
        onKeyDown={toggleSidebar}
        sx={{
          paddingTop: isSmallScreen ? "60px" : 0,
        }}
      >
        {/* Logo Section */}
        <Box sx={{ padding: 2, display: "flex", justifyContent: "center" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src="/assets/img/whiz-cyber-logo.png" // Replace with your logo image path
              alt="Logo"
              style={{ width: "100px", height: "auto" }} // Adjust size of the logo
            />
          </Link>
        </Box>

        {/* Navigation Links */}
        <List>
          {sidebarItems.map((item) => (
            <ListItem
              button
              key={item.id}
              component={Link}
              to={item.path}
              sx={{
                backgroundColor: isActive(item.path)
                  ? "#635bff"
                  : "transparent", // Active state background color
                "&:hover": {
                  backgroundColor: "#21222d", // Hover state background color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 35, // Adjust space between icon and text
                }}
              >
                {/* Render the icon using Iconify */}
                <Icon icon={item.icon} width={25} height={25} color="#fff" />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{
                  color: "#fff",
                  marginLeft: "10px", // Increase space between icon and text
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

// Sidebar.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   toggleSidebar: PropTypes.func.isRequired,
//   isSmallScreen: PropTypes.bool.isRequired,
// };

export default Sidebar;
