import { jwtDecode } from "jwt-decode";

export const isTokenValid = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return exp * 1000 > Date.now(); // Token is valid if it's not expired
  } catch {
    return false;
  }
};

export const tokenName = "quantumfinancialsystem";
export const saveToken = (token) => {
  localStorage.setItem(tokenName, JSON.stringify(token));
};
export const retrieveToken = () => {
  // const token = localStorage.getItem(tokenName);
  // if (!token) return null;
  // return JSON.parse(token);

  const token = localStorage.getItem(tokenName);
  return token;
};

export const deleteToken = () => {
  localStorage.removeItem(tokenName);
};
