import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Avatar, // Import Avatar for circular image
  Button,
  Tooltip,
  TextField,
} from "@mui/material";
import Layout from "../components/Layout";
import { Icon } from "@iconify/react"; // Import Icon from Iconify
import { useTransaction } from "../context/TransactionContext";

const Transactions = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchTerm, setSearchTerm] = useState(""); // State to store search term

  const { transactions } = useTransaction();

  // Filter transactions based on search term
  const filteredTransactions = transactions.filter((transaction) => {
    const searchValue = searchTerm.toLowerCase();
    return (
      transaction.paymentMethod.toLowerCase().includes(searchValue) ||
      transaction.bank.toLowerCase().includes(searchValue) ||
      transaction.status.toLowerCase().includes(searchValue)
    );
  });

  // if (loading) return <p>Loading transactions...</p>;
  // if (error) return <p>Error: {error}</p>;

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: "50px",
          backgroundColor: "#21222d",
          minHeight: "100vh",
          color: "white",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            width: "100%",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: 3,
              backgroundColor: "#171821",
            }}
          >
            <CardContent
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h4"
                component="div"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#fff" }}
              >
                Transactions
              </Typography>
              <Box
                sx={{
                  maxWidth: "500px",
                  margin: "auto",
                }}
              >
                <Typography
                  variant="body1"
                  color="#9998a0"
                  sx={{ fontSize: 18 }}
                >
                  History of transactions in your account
                </Typography>
              </Box>
              {/* Search Input */}
              <Box sx={{ marginTop: 3 }}>
                <TextField
                  label="Search by PaymentMethod or Status or Bank"
                  variant="outlined"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    maxWidth: "500px",
                    margin: "auto",
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Transactions Header with All Transactions and Filter */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 1,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
            All Transactions
          </Typography>
          <Tooltip title="Filter transactions">
            <Button
              variant="outlined"
              sx={{
                color: "#fff",
                borderColor: "#9998a0",
                "&:hover": { borderColor: "#fff" },
              }}
              startIcon={<Icon icon="material-symbols:filter-list" />}
            >
              Filter
            </Button>
          </Tooltip>
        </Box>

        {/* Transactions List */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: "#21222d",
            color: "white",
            height: "auto",
          }}
        >
          {/* Transactions List */}
          <Box>
            {filteredTransactions?.map((transaction) => (
              <Card
                key={transaction._id}
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#171821",
                  borderRadius: 3,
                  boxShadow: 2,
                  padding: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* Circular Image */}
                <Avatar
                  src={transaction.image}
                  alt={transaction.type}
                  sx={{
                    width: 56,
                    height: 56,
                    marginRight: 2,
                  }}
                />
                {/* Transaction Details */}
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {transaction.type}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#9998a0", fontSize: 14 }}
                  >
                    {transaction.description} {transaction.date}
                  </Typography>
                </Box>
                {/* Amount and Status */}
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    ${transaction.amount}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#635bff",
                      fontSize: 14,
                      marginTop: "4px",
                    }}
                  >
                    {transaction.status}
                  </Typography>
                </Box>
              </Card>
            ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Transactions;
