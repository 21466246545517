import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Card,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import theme from "../theme";

function About() {
  return (
    <Box
      sx={{ py: 6, backgroundColor: theme.palette.background.default }}
      id="about"
    >
      <Container data-aos="fade-up" data-aos-delay="100">
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="space-between"
        >
          {/* Left Section */}
          <Grid item xs={12} md={6} data-aos="fade-up" data-aos-delay="200">
            <Box>
              <Typography
                variant="h5"
                color="#4aa5f0"
                gutterBottom
                sx={{
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                About QFS
              </Typography>
              <Typography
                variant="h3"
                color="#fff"
                gutterBottom
                sx={{
                  fontWeight: "500",
                  fontFamily: "Faculty Glyphic, sans-serif",
                  fontSize: { xs: "1.8rem", sm: "2.5rem" },
                }}
              >
                Quantum Financial System
              </Typography>
              <Typography
                variant="body1"
                // color="#fff"
                color="#9998a0"
                paragraph
              >
                QFS literal meaning is Quantum Financial System, which is an
                advanced financial system launched to eradicate monopoly on
                monetary system and for that purpose, a system that comprises of
                Artificial Intelligence and complex computer programs fully
                backed by banks is needed. Quantum Financial System would be a
                breakthrough in the world of banking which will lead to a new
                era of banking. QFS will not be influenced by Government
                policies, rather it will be entirely backed by tangible assets
                like Gold, Platinum, Oil and will not be based upon mere piece
                of papers which have no evidentiary value.
              </Typography>
            </Box>

            {/* Features List */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      Assets Security
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      Debt Cancellation
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      QFS Debit/Credit Cards
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      QFS Humanitarian Project
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      Wallet Synchronization
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      Decentralization
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      Asset Recovery
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircle sx={{ color: "#4aa5f0" }} />
                    </ListItemIcon>
                    <Typography variant="body1" color="#fff">
                      Asset Backed Coin
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6} data-aos="fade-up" data-aos-delay="300">
            <Box position="relative">
              <Card
                variant="none"
                sx={{ borderRadius: 4, backgroundColor: "transparent" }}
              >
                <img
                  src="assets/img/about-image.png"
                  alt="Business Meeting"
                  className="img-fluid main-image rounded-4"
                  style={{ width: "100%", borderRadius: "20px" }}
                />
                {/* <img
                  src="assets/img/about-image2.png"
                  alt="Team Discussion"
                  className="img-fluid small-image rounded-4"
                  style={{
                    position: "absolute",
                    top: "20%",
                    left: "10%",
                    width: "40%",
                    borderRadius: "4px",
                  }}
                /> */}
                {/* <Box
                  position="absolute"
                  top="30%"
                  left="50%"
                  sx={{
                    transform: "translateX(-50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h4" fontWeight="bold">
                    15+ <span style={{ fontSize: "20px" }}>Years</span>
                  </Typography>
                  <Typography variant="body1" color="#fff">
                    Of experience in financial services
                  </Typography>
                </Box> */}
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default About;
