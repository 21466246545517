import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const Layout = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar toggleSidebar={toggleSidebar} isSmallScreen={isSmallScreen} />
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        isSmallScreen={isSmallScreen}
      />
      {children}
    </Box>
  );
};

export default Layout;

// import React, { useState } from "react";
// import { Box, useMediaQuery, useTheme } from "@mui/material";
// import Navbar from "../components/Navbar";
// import Sidebar from "../components/Sidebar";

// const Layout = ({ children }) => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen((prev) => !prev); // Toggle state correctly
//   };

//   return (
//     <Box sx={{ display: "flex" }}>
//       {/* Make sure the props are being passed correctly */}
//       <Navbar toggleSidebar={toggleSidebar} isSmallScreen={isSmallScreen} />
//       <Sidebar
//         isOpen={isSidebarOpen}
//         toggleSidebar={toggleSidebar}
//         isSmallScreen={isSmallScreen}
//       />
//       {children}
//     </Box>
//   );
// };

// export default Layout;
