// import React, { createContext, useContext, useEffect, useState } from "react";
// import axios from "axios";
// import { baseUrl } from "../utils/api";
// import { retrieveToken } from "../utils/auth";
// import { tokenName } from "../utils";
// import { useUser } from "./UserContext";

// // Create Transaction Context
// export const TransactionContext = createContext();

// // Transaction Provider
// export const TransactionProvider = ({ children }) => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [transactions, setTransactions] = useState([]);

//   const { user } = useUser();

//   // Deposit funds
//   const depositFunds = async (
//     amount,
//     currency,
//     paymentMethod,
//     bank,
//     attachedFile
//   ) => {
//     setLoading(true);
//     setError(null);

//     // const token = retrieveToken();
//     const token = localStorage.getItem(tokenName);

//     try {
//       const response = await axios.post(
//         `${baseUrl}/transaction/deposit`,
//         { amount, currency, paymentMethod, bank, attachedFile },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       return response.data; // Successful deposit response
//     } catch (err) {
//       console.error(
//         "Error in deposit:",
//         err.response?.data?.message || err.message
//       );
//       setError(err.response?.data?.message || "Failed to deposit funds.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Withdraw funds
//   const withdrawFunds = async (amount, currency) => {
//     setLoading(true);
//     setError(null);
//     const token = retrieveToken();

//     try {
//       const response = await axios.post(
//         `${baseUrl}/transaction/withdraw`,
//         { amount, currency },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       return response.data; // Successful withdrawal response
//     } catch (err) {
//       console.error(
//         "Error in withdrawal:",
//         err.response?.data?.message || err.message
//       );
//       setError(err.response?.data?.message || "Failed to withdraw funds.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Exchange funds
//   const exchangeFunds = async (
//     fromCurrency,
//     toCurrency,
//     amount,
//     exchangeRate
//   ) => {
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await axios.post(
//         `${baseUrl}/transaction/exchange`,
//         { fromCurrency, toCurrency, amount, exchangeRate },
//         { headers: { Authorization: `Bearer ${retrieveToken()}` } }
//       );
//       return response.data; // Successful exchange response
//     } catch (err) {
//       console.error(
//         "Error in exchange:",
//         err.response?.data?.message || err.message
//       );
//       setError(err.response?.data?.message || "Failed to exchange funds.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Transfer funds
//   const transferFunds = async (recipientId, amount, currency) => {
//     setLoading(true);
//     setError(null);
//     const token = retrieveToken();

//     try {
//       const response = await axios.post(
//         `${baseUrl}/transaction/transfer`,
//         { recipientId, amount, currency },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       return response.data; // Successful transfer response
//     } catch (err) {
//       console.error(
//         "Error in transfer:",
//         err.response?.data?.message || err.message
//       );
//       setError(err.response?.data?.message || "Failed to transfer funds.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch transaction history
//   const fetchTransactions = async () => {
//     if (user?.isAdmin) {
//       setLoading(true);
//       setError(null);

//       const token = retrieveToken();

//       try {
//         const response = await axios.get(`${baseUrl}/transaction`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         // return response.data.transactions; // List of transactions
//         const transactionData = response.data.transactions; // Extract user data
//         setTransactions(transactionData);
//       } catch (err) {
//         console.error(
//           "Error fetching history:",
//           err.response?.data?.message || err.message
//         );
//         setError(
//           err.response?.data?.message || "Failed to fetch transaction history."
//         );
//       } finally {
//         setLoading(false);
//       }
//     } else {
//       console.log("Access Denied: Admin privileges required.");
//     }
//   };

//   // Fetch users whenever the user state changes (Admin check)
//   useEffect(() => {
//     if (user?.isAdmin) fetchTransactions();
//   }, [user]);

//   // Context value
//   const contextValue = {
//     loading,
//     error,
//     transactions,
//     depositFunds,
//     withdrawFunds,
//     exchangeFunds,
//     transferFunds,
//     fetchTransactions,
//   };

//   return (
//     <TransactionContext.Provider value={contextValue}>
//       {children}
//     </TransactionContext.Provider>
//   );
// };

// // Custom Hook
// export const useTransaction = () => useContext(TransactionContext);

import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/api";
import { retrieveToken } from "../utils/auth";
import { useUser } from "./UserContext";

// Create Transaction Context
export const TransactionContext = createContext();

// Transaction Provider
export const TransactionProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const { user } = useUser();

  // Standard API call wrapper
  const apiCall = async (endpoint, method, data = {}) => {
    setLoading(true);
    setError(null);
    const token = retrieveToken();

    try {
      const response = await axios({
        url: `${baseUrl}/transaction/${endpoint}`,
        method,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (err) {
      console.error(
        `Error in ${endpoint}:`,
        err.response?.data?.message || err.message
      );
      setError(
        err.response?.data?.message ||
          `Failed to perform ${endpoint} operation.`
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Deposit funds
  const depositFunds = async (
    amount,
    currency,
    paymentMethod,
    bank,
    attachedFile
  ) => {
    return await apiCall("deposit", "POST", {
      amount,
      currency,
      paymentMethod,
      bank,
      attachedFile,
    });
  };

  // Withdraw funds
  const withdrawFunds = async (amount, currency) => {
    return await apiCall("withdraw", "POST", { amount, currency });
  };

  // Exchange funds
  const exchangeFunds = async (
    fromCurrency,
    toCurrency,
    amount,
    exchangeRate
  ) => {
    return await apiCall("exchange", "POST", {
      fromCurrency,
      toCurrency,
      amount,
      exchangeRate,
    });
  };

  // Transfer funds
  const transferFunds = async (recipientId, amount, currency) => {
    return await apiCall("transfer", "POST", { recipientId, amount, currency });
  };

  // Fetch transaction history
  const fetchTransactions = async () => {
    if (!user?.isAdmin) {
      setError("Access Denied: Admin privileges required.");
      return;
    }
    try {
      const data = await apiCall("", "GET");
      setTransactions(data.transactions || []);
    } catch (err) {
      console.error("Failed to fetch transaction history.");
    }
  };

  // // Fetch transactions whenever the user state changes (Admin check)
  // useEffect(() => {
  //   if (user?.isAdmin) fetchTransactions();
  // }, [user]);

  useEffect(() => {
    if (user?.isAdmin) {
      // Fetch transactions if the user is an admin
      fetchTransactions().catch((error) => {
        console.error("Error fetching transactions:", error.message);
      });
    }
  }, [user]);

  // Context value
  const contextValue = {
    loading,
    error,
    transactions,
    depositFunds,
    withdrawFunds,
    exchangeFunds,
    transferFunds,
    fetchTransactions,
  };

  return (
    <TransactionContext.Provider value={contextValue}>
      {children}
    </TransactionContext.Provider>
  );
};

// Custom Hook
export const useTransaction = () => useContext(TransactionContext);
