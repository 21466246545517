export const services = [
  {
    title: "Assets Security",
    description:
      " Our System is highly fortified against any form of cyber attacks that could result to any type of Assets",
    icon: "shield",
  },
  {
    title: "Wallet Synchronization",
    description:
      "Sync your wallet after successful onboarding on Quantum Financial System for atmost digital wallet security",
    icon: "wallet",
  },
  {
    title: "QFS Debit/Credit Cards",
    description:
      " Funds on Quantum Financial System can also be accessed via QFS Cards to shop Globally",
    icon: "credit-card",
  },
  {
    title: "Stolen Assets Recovery",
    description:
      "We deploy our sophisticated softwares in conjuction Law Enforcement Agency to track and recover stolen digital assets globally.",
    icon: "shield-check",
  },
  {
    title: "QFS Humanitarian Project",
    description:
      "Users are expected to verify their identity after which they will apply for their Humanitarian Project.",
    icon: "folder",
  },
];
