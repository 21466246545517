import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
} from "@mui/material";
import Toast, { showErrorToast, showSuccessToast } from "../components/Toast";
import { useAuth } from "../context/AuthContext"; // Import useAuth
import theme from "../theme";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { register, loading, setLoading } = useAuth(); // Get register function from context

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      showErrorToast("Enter a valid email address");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      showErrorToast("Passwords do not match");
      return;
    }

    const requestBody = {
      firstName,
      lastName,
      email,
      phone,
      password,
    };

    try {
      setLoading(true);
      const response = await register(requestBody); // Use register from context

      if (response.status === 201) {
        showSuccessToast("Registration successful");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (error) {
      showErrorToast(error.response?.data?.message || "Registration failed");
      console.error("Error during registration:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="false"
      sx={{
        height: "auto",
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#161d2c",
        backgroundColor: theme.palette.background.default,
        padding: 0,
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <Toast />
      <Grid container sx={{ height: "100%" }}>
        {/* Left Section (Image) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <img
            src="/assets/img/signup-static-img.svg"
            alt="Register illustration"
            style={{ maxWidth: "80%", maxHeight: "300px" }}
          />
        </Grid>

        {/* Right Section (Form) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box
            component="form"
            onSubmit={handleRegister}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
              backgroundColor: "white",
              borderRadius: 5,
              boxShadow: 3,
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src="/assets/img/whiz-cyber-logo.png"
                alt="Open Financial System"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "20px",
                }}
              />
            </Link>

            <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Box>
            {/* <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            /> */}
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!emailRegex.test(email) && email.length > 0}
              helperText={
                !emailRegex.test(email) && email.length > 0
                  ? "Enter a valid email address"
                  : ""
              }
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{
                mt: 2,
                fontSize: "1rem",
                padding: "14px",
                height: "50px",
                background: "#4aa5f0",
                color: "#ffffff",
                "&:hover": {
                  background: "#4aa5f0",
                },
                transition: "all 0.3s ease",
                textTransform: "none",
                borderRadius: 50,
              }}
            >
              {loading ? "Loading..." : "Continue"}
            </Button>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="body3"
                color="textSecondary"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "400",
                }}
              >
                Already have an account?{" "}
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "#4aa5f0" }}
                >
                  Sign in here{" "}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Register;
