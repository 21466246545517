// import React from "react";
// import { services } from "../utils/data";
// import { Link } from "react-router-dom";

// function Services() {
//   return (
//     <section id="services" className="services section light-background">
//       {/*  Section Title  */}
//       <div className="container section-title" data-aos="fade-up">
//         <h2 className="text-white">Our Services</h2>
//         {/* <p>
//           Empowering your financial journey with innovative solutions for a
//           secure future.
//         </p> */}
//       </div>
//       {/* End Section Title */}

//       <div className="container" data-aos="fade-up" data-aos-delay="100">
//         <div className="row g-4">
//           {/* Service Card 1 */}
//           {services.map((service, index) => (
//             <div
//               key={index}
//               className="col-lg-6"
//               data-aos="fade-up"
//               data-aos-delay="100"
//             >
//               <div className="service-card d-flex">
//                 <div className="icon flex-shrink-0">
//                   <i class={`bi bi-${service.icon}`}></i>
//                   {/* Changed icon to wallet */}
//                 </div>
//                 <div>
//                   <h3>{service.title}</h3>
//                   <p>{service.description}</p>
//                   <Link to="/login" className="read-more">
//                     Read More <i className="bi bi-arrow-right"></i>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Services;

import React from "react";
import { services } from "../utils/data";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { Verified } from "@mui/icons-material";
import theme from "../theme";

function Services() {
  return (
    <Box
      id="services"
      sx={{
        // backgroundColor: "#171821",
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Section Title */}
      <Box sx={{ py: 6 }}>
        <Container>
          <Typography
            variant="h4"
            color="white"
            align="center"
            gutterBottom
            style={{
              fontWeight: "500",
              fontFamily: "Faculty Glyphic, sans-serif",
            }}
          >
            Our Services
          </Typography>
          {/* <Typography variant="body1" color="white" align="center">
            Empowering your financial journey with innovative solutions for a secure future.
          </Typography> */}
        </Container>
      </Box>
      {/* End Section Title */}

      <Container sx={{ pb: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          {/* Service Card 1 */}
          {services.map((service, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  borderRadius: 5,
                }}
              >
                {/* Icon Section */}
                <Box sx={{ mr: 2 }}>
                  <Verified sx={{ mr: 1, color: "#4aa5f0" }} />
                </Box>

                <CardContent>
                  <Typography
                    variant="h6"
                    color="#000"
                    fontSize="large"
                    fontWeight="600"
                  >
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    mb={2}
                    sx={{ fontSize: 16 }}
                  >
                    {service.description}
                  </Typography>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "#4aa5f0" }}
                  >
                    Read More
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Services;
