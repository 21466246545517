import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Modal,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import Layout from "../components/Layout";
import { baseUrl } from "../utils/api";
import { tokenName } from "../utils";
import { createBrowserHistory } from "history";

const Profile = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const { user } = useAuth(); // User fetched from context
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const history = createBrowserHistory();

  const theme = useTheme(); // Hook to get the theme object
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Ensure user is not null or undefined
    if (user) {
      setUserInfo({
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        phone: user?.phone || "",
        address1: user?.address1 || "",
        address2: user?.address2 || "",
        city: user?.city || "",
        state: user?.state || "",
        country: user?.country || "",
      });
    }
  }, [user]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const token = localStorage.getItem(tokenName);
    try {
      await axios.put(`${baseUrl}/user/update`, userInfo, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setLoading(false);
      history.push("/profile"); // Navigate to the desired route
      history.go(0); // Refresh the page
      // setOpen(false);
    } catch (error) {
      setLoading(false);
      console.error(
        "Error updating user profile:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <Layout>
      {!user ? (
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            marginTop: "50px",
            color: "white",
            textAlign: "center",
            backgroundColor: "#21222d",
            minHeight: "100vh",
          }}
        >
          <Typography variant="h5" sx={{ color: "#9998a0" }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: 2,
            marginTop: "50px",
            backgroundColor: "#21222d",
            color: "white",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              my: 5,
            }}
          >
            <Card
              sx={{
                backgroundColor: "#171821",
                borderRadius: 3,
                boxShadow: 2,
                border: "1px solid #444",
              }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: 30,
                    mb: 3,
                  }}
                >
                  Profile Information
                  <IconButton
                    sx={{ color: "#9998a0", marginLeft: 2, cursor: "pointer" }}
                    onClick={handleOpen}
                  >
                    <EditIcon sx={{ color: "#635bff", cursor: "pointer" }} />
                  </IconButton>
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    Name
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.firstName} {user.lastName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    Phone
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.phone}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    Address 1
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.address1}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    Address 2
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.address2}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    City
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.city}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    State
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.state}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #444",
                    paddingBottom: 1,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    Country
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#9998a0", marginTop: 1 }}
                  >
                    {user.country}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>

          {/* Modal for Editing */}
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                width: isSmallScreen ? "500px" : "300px", // Adjust width here
                backgroundColor: "#fff",
                padding: 3,
                borderRadius: 3,
                margin: "auto",
                top: "5%",
                position: "relative",
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Typography variant="h6" sx={{ color: "#000", marginBottom: 2 }}>
                Edit Profile Information
              </Typography>

              <TextField
                label="First Name"
                value={userInfo.firstName}
                name="firstName"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Last Name"
                value={userInfo.lastName}
                name="lastName"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Phone"
                value={userInfo.phone}
                name="phone"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Address 1"
                value={userInfo.address1}
                name="address1"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Address 2"
                value={userInfo.address2}
                name="address2"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="City"
                value={userInfo.city}
                name="city"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="State"
                value={userInfo.state}
                name="state"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Country"
                value={userInfo.country}
                name="country"
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={handleInputChange}
              />

              <Box sx={{ textAlign: "center", marginTop: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSaveChanges}
                  disabled={loading}
                  sx={{
                    width: "100%",
                    fontSize: "1rem", // Increase the font size for better visibility
                    padding: "14px", // Adjust padding to make the button bigger
                    height: "50px", // Set a fixed height for the button
                    background: "#635bff",
                    "&:hover": {
                      background: "#635bff", // Darker hover color
                    },
                    transition: "all 0.3s ease", // Smooth transition for hover effect
                    textTransform: "none",
                    borderRadius: 50,
                  }}
                >
                  {loading ? "Saving..." : "Save Changes"}
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </Layout>
  );
};

export default Profile;
