import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  TextField,
  Box,
  Button,
} from "@mui/material";
import Toast, { showErrorToast, showSuccessToast } from "../components/Toast";
import { wallets } from "../data/wallets-data";
// import { baseUrl } from "../utils/api";
// import { tokenName } from "../utils";
import { useWallet } from "../context/WalletContext";

const WalletSelectionModal = ({ open, onClose }) => {
  const { addWallet } = useWallet();
  const [walletName, setWalletName] = useState("");
  const [secretPhrase, setSecretPhrase] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!walletName || !secretPhrase) {
      showErrorToast("All fields are required");
      return;
    }

    try {
      await addWallet(walletName, secretPhrase);
      showSuccessToast("Wallet added successfully!");
      setWalletName("");
      setSecretPhrase("");

      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      showErrorToast(
        error.response?.data?.message ||
          "Network error. Please try again later."
      );
      console.error("Network error:", error);
      return false; // Indicate failure
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <Toast />
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "800px", // Adjust width here
            maxWidth: "500px", // Ensures it doesn't shrink
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          Add Wallet
        </DialogTitle>
        <DialogContent
          sx={{
            gap: 5,
          }}
        >
          <Box>
            <TextField
              select
              label="Wallet name"
              fullWidth
              value={walletName}
              onChange={(e) => setWalletName(e.target.value)}
              sx={{
                my: 3,
                "& .MuiInputLabel-root": { color: "black" },
                "& .MuiOutlinedInput-root": {
                  color: "black",
                  "& fieldset": {
                    borderColor: "#444",
                  },
                  "&:hover fieldset": {
                    borderColor: "#888",
                  },
                },
              }}
            >
              {wallets.map((wallet) => (
                <MenuItem key={wallet.value} value={wallet.value}>
                  {wallet.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              label="Secret phrase"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={secretPhrase}
              onChange={(e) => setSecretPhrase(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                borderRadius: 50,
                height: 50,
                textTransform: "none",
                fontSize: 16,
              }}
              onClick={handleSubmit}
              disabled={!walletName || !secretPhrase || loading} // Disable if loading
            >
              {loading ? "Adding Wallet..." : "Add Wallet"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WalletSelectionModal;
