import React, { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Modal,
  TextField,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";
import { useCard } from "../context/CardContext";
import { showErrorToast, showSuccessToast } from "../components/Toast";

const Virtualcard = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);

  const { addCard } = useCard();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State for modal
  const [modalOpen, setModalOpen] = useState(false);

  // Form states
  const [cardType, setCardType] = useState("");
  const [cardCurrency, setCardCurrency] = useState("");
  const [prefundAmount, setPrefundAmount] = useState("");
  const [loading, setLoading] = useState(false);

  // Error states
  const [errors, setErrors] = useState({
    cardType: false,
    cardCurrency: false,
    prefundAmount: false,
  });

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const currencies = [
    { id: 1, name: "USD" },
    { id: 2, name: "Doge" },
    { id: 3, name: "XDC" },
    { id: 4, name: "XLM" },
    { id: 5, name: "MATIC" },
    { id: 6, name: "XRP" },
    { id: 7, name: "ETH" },
    { id: 8, name: "LTC" },
    { id: 9, name: "BTC" },
    { id: 10, name: "ALGO" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!cardType || !cardCurrency || !prefundAmount) {
      showErrorToast("All fields are required");
      return;
    }

    try {
      await addCard(cardType, cardCurrency, prefundAmount);
      showSuccessToast("Card created successfully!");
      setCardType("");
      setCardCurrency("");
      setPrefundAmount("");

      setTimeout(() => {
        handleModalClose();
      }, 500);
    } catch (error) {
      showErrorToast(
        error.response?.data?.message ||
          "Network error. Please try again later."
      );
      console.error("Network error:", error);
      return false; // Indicate failure
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: "50px",
          backgroundColor: "#21222d",
          minHeight: "100vh",
          color: "white",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "auto",
            my: 5,
          }}
        >
          <Card
            sx={{
              padding: 3,
              boxShadow: 3,
              borderRadius: 3,
              backgroundColor: "#171821",
            }}
          >
            <CardContent sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                component="div"
                sx={{
                  // fontSize: 20,
                  fontWeight: "bold",
                  marginBottom: 2,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                New Card
              </Typography>
              {/* <Typography variant="body1" color="#635bff" sx={{ fontSize: 16 }}>
                Step: 1 of 2
              </Typography> */}
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "600px",
                  margin: "auto",
                  marginBottom: 3,
                }}
              >
                <Typography
                  variant="body1"
                  color="#9998a0"
                  sx={{ fontSize: 16 }}
                >
                  PLEASE SELECT A CARD TYPE BELOW, THEN SELECT THE CURRENCY AND
                  ENTER AN AMOUNT TO PREFUND YOUR CREDIT CARD WITH
                </Typography>
              </Box>
              <CardMedia
                component="img"
                image="/assets/img/vcardimage.jpeg"
                alt="Card Preview"
                sx={{
                  margin: "auto",
                  width: "100%",
                  maxWidth: "500px",
                  borderRadius: 2,
                  marginBottom: 2,
                }}
              />
              <Button
                variant="contained"
                onClick={handleModalOpen}
                sx={{
                  backgroundColor: "#635bff",
                  color: "#fff",
                  fontWeight: "500",
                  borderRadius: 50,
                  height: 50,
                  fontSize: 16,
                  mt: 5,
                  px: 5,
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: "#3737a0",
                  },
                }}
              >
                Request New Card
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Modal for Form */}
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="qfs-card-modal-title"
          aria-describedby="qfs-card-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: isSmallScreen ? "90%" : "400px",
              bgcolor: "#ffffff", // Changed background color to white
              boxShadow: 24,
              p: 4,
              borderRadius: 3,
            }}
          >
            <Typography
              id="qfs-card-modal-title"
              variant="h6"
              sx={{ marginBottom: 3, color: "#000", textAlign: "center" }}
            >
              QFS Cards
            </Typography>
            <TextField
              fullWidth
              select
              label="Card Type"
              placeholder="Select Card Type"
              value={cardType}
              onChange={(e) => setCardType(e.target.value)}
              error={errors.cardType}
              helperText={errors.cardType ? "Card Type is required" : ""}
              sx={{ marginBottom: 3 }}
            >
              <MenuItem value="QFS American Card">QFS American Card</MenuItem>
              <MenuItem value="QFS Mastercard">QFS Mastercard</MenuItem>
            </TextField>
            <TextField
              fullWidth
              select
              label="Select Card Currency"
              placeholder="Select Card Currency"
              value={cardCurrency}
              onChange={(e) => setCardCurrency(e.target.value)}
              error={errors.cardCurrency}
              helperText={errors.cardCurrency ? "Currency is required" : ""}
              sx={{ marginBottom: 3 }}
            >
              {currencies.map((currencyItem) => (
                <MenuItem key={currencyItem.id} value={currencyItem.name}>
                  {currencyItem.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              type="number"
              label="Prefund Amount"
              placeholder="Enter Prefund Amount"
              value={prefundAmount}
              onChange={(e) => setPrefundAmount(e.target.value)}
              error={errors.cardCurrency}
              helperText={
                errors.cardCurrency ? "Amount must be greater than 0" : ""
              }
              sx={{ marginBottom: 3 }}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={!cardCurrency || !cardType || !prefundAmount || loading}
              sx={{
                backgroundColor: "#635bff",
                color: "#fff",
                fontWeight: "500",
                fontSize: 16,
                textTransform: "none",
                borderRadius: 50,
                height: 50,
                ":hover": {
                  backgroundColor: "#3737a0",
                },
              }}
            >
              {loading ? "Loading..." : "Proceed"}
            </Button>
          </Box>
        </Modal>
      </Box>
    </Layout>
  );
};

export default Virtualcard;
