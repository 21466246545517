// export const sidebarMenu = [
//   {
//     id: 1,
//     path: "/dashboard",
//     title: "Dashboard",
//     icon: "mingcute:home-1-fill",
//   },
//   {
//     id: 2,
//     path: "/wallets",
//     title: "Wallets",
//     icon: "fa-solid:wallet",
//   },
//   {
//     id: 3,
//     path: "/virtualcard",
//     title: "QFS Cards",
//     icon: "fa-solid:credit-card",
//   },
//   {
//     id: 4,
//     path: "/transactions",
//     title: "Transactions",
//     icon: "fa-solid:exchange-alt",
//   },
//   {
//     id: 5,
//     path: "/deposit",
//     title: "Deposit Money",
//     icon: "fa-solid:money-bill-wave",
//   },
//   {
//     id: 6,
//     path: "/investment",
//     title: "Investment",
//     icon: "fa-solid:chart-line",
//   },
//   {
//     id: 7,
//     path: "/send-money",
//     title: "Send Money",
//     icon: "fa-solid:paper-plane",
//   },
//   {
//     id: 8,
//     path: "/request-money",
//     title: "Request Money",
//     icon: "fa-solid:wallet",
//   },
//   {
//     id: 9,
//     path: "/exchange",
//     title: "Exchange Money",
//     icon: "ic:round-history",
//   },
//   {
//     id: 10,
//     path: "/withdrawals",
//     title: "Withdrawals",
//     icon: "fa-solid:hand-holding-usd",
//   },
// ];

export const sidebarMenu = [
  {
    id: 1,
    path: "/dashboard",
    title: "Dashboard",
    icon: "solar:widget-3-bold",
  },
  { id: 2, path: "/wallets", title: "Wallets", icon: "fa-solid:wallet" },
  {
    id: 3,
    path: "/virtualcard",
    title: "QFS Cards",
    icon: "fa-solid:credit-card",
  },
  {
    id: 4,
    path: "/transactions",
    title: "Transactions",
    icon: "fa6-solid:money-bill-transfer",
  },
  {
    id: 5,
    path: "/deposit",
    title: "Deposit Money",
    icon: "fa-solid:money-bill-wave",
  },
  {
    id: 6,
    path: "/investment",
    title: "Investment",
    icon: "fa-solid:chart-line",
  },
  {
    id: 7,
    path: "/send-money",
    title: "Send Money",
    icon: "iconamoon:send-fill",
  },
  {
    id: 8,
    path: "/request-money",
    title: "Request Money",
    icon: "fa6-solid:money-bill-transfer",
  },
  {
    id: 9,
    path: "/exchange",
    title: "Exchange Money",
    icon: "fa-solid:exchange-alt",
  },
  {
    id: 10,
    path: "/withdraw",
    title: "Withdraw Money",
    icon: "uil:money-withdraw",
  },
];
