// PrivateRoute.js

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { tokenName } from "../utils";

// function PrivateRoute({ element: Component }) {
//   const token = localStorage.getItem(tokenName);

//   return token ? <Component /> : <Navigate to="/login" />;
// }

// export default PrivateRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { tokenName } from "../utils";
import { isTokenValid } from "../utils/auth";

function PrivateRoute({ element: Component }) {
  const token = localStorage.getItem(tokenName);

  return token && isTokenValid(token) ? (
    <Component />
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoute;
