// import React from "react";

// function Hero() {
//   return (
//     <section id="hero" className="hero section">
//       <div className="container" data-aos="fade-up" data-aos-delay="100">
//         <div className="row align-items-center">
//           <div className="col-lg-6">
//             <div
//               className="hero-content"
//               data-aos="fade-up"
//               data-aos-delay="200"
//             >
//               <div className="company-badge mb-4">
//                 <i className="bi bi-patch-check-fill me-2"></i>
//                 Trusted, Reliable, & Secure
//               </div>

//               <h1 className="mb-4">
//                 Quantum Financial System <br />
//                 For Everyone <br />
//                 {/* <span className="accent-text">One Step at a Time</span> */}
//               </h1>

//               <p className="mb-4 mb-md-5 text-gray">
//                 {/* At Quantum Financial System, we believe in a brighter financial
//                 future for all. Our tools and guidance are designed to help you
//                 make informed decisions, reach your goals, and build lasting
//                 wealth. */}
//                 QFS is a decentralized financial system designed to democratize
//                 money and eliminate central bank control.
//               </p>

//               <div className="hero-buttons">
//                 <a
//                   href="/register"
//                   className="btn btn-primary me-0 me-sm-2 mx-1"
//                 >
//                   Get Started
//                 </a>
//                 {/* <a
//                   href="/login"
//                   className="btn btn-transparent btn-lg fs-6 rounded-pill border border-2 border-secondary-sudtle me-0 me-sm-2 mx-1 px-5"
//                 >
//                   Login
//                 </a> */}
//                 <a
//                   href="/login"
//                   className="btn btn-primary text-primary border-white bg-white me-0 me-sm-2 mx-1"
//                 >
//                   Login
//                 </a>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-6">
//             <div
//               className="hero-image"
//               data-aos="zoom-out"
//               data-aos-delay="300"
//             >
//               <img
//                 src="assets/img/hero-image.png"
//                 alt="Financial Success"
//                 className="img-fluid"
//               />

//               <div className="customers-badge">
//                 <div className="customer-avatars">
//                   <img
//                     src="assets/img/avatar-1.webp"
//                     alt="Customer 1"
//                     className="avatar"
//                   />
//                   <img
//                     src="assets/img/avatar-2.webp"
//                     alt="Customer 2"
//                     className="avatar"
//                   />
//                   <img
//                     src="assets/img/avatar-3.webp"
//                     alt="Customer 3"
//                     className="avatar"
//                   />
//                   <img
//                     src="assets/img/avatar-4.webp"
//                     alt="Customer 4"
//                     className="avatar"
//                   />
//                   <img
//                     src="assets/img/avatar-5.webp"
//                     alt="Customer 5"
//                     className="avatar"
//                   />
//                   <span className="avatar more">12+</span>
//                 </div>
//                 <p className="mb-0 mt-2">
//                   Trusted by millions of users worldwide to achieve their
//                   financial goals.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* <div
//           className="row stats-row gy-4 mt-5"
//           data-aos="fade-up"
//           data-aos-delay="500"
//         >
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-trophy"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>Award-Winning Service</h4>
//                 <p className="mb-0">
//                   Recognized for excellence in financial guidance.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-briefcase"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>6.5k Successful Plans</h4>
//                 <p className="mb-0">
//                   Helping clients build tailored financial solutions.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-graph-up"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>80k Hours of Insight</h4>
//                 <p className="mb-0">
//                   Providing expert advice to elevate your journey.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-award"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>6 Global Accolades</h4>
//                 <p className="mb-0">
//                   Proudly recognized for innovative financial solutions.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div> */}
//         <div
//           className="row stats-row gy-4 mt-5"
//           data-aos="fade-up"
//           data-aos-delay="500"
//         >
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-wallet"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>Wallets</h4>
//                 <p className="mb-0"> More than 10 Million</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-briefcase"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>Assets</h4>
//                 <p className="mb-0">More than $500 Billion Secured</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-graph-up-arrow"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>Transactions</h4>
//                 <p className="mb-0">Billions Processed Monthly</p>
//               </div>
//             </div>
//           </div>
//           <div className="col-lg-3 col-md-6">
//             <div className="stat-item">
//               <div className="stat-icon">
//                 <i className="bi bi-infinity"></i>
//               </div>
//               <div className="stat-content">
//                 <h4>Exchanges</h4>
//                 <p className="mb-0">Over 200+ Integrated Globally</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Hero;

import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import Wallet from "@mui/icons-material/Wallet";
import Assets from "@mui/icons-material/BusinessCenter";
import Transactions from "@mui/icons-material/TrendingUp";
import Exchanges from "@mui/icons-material/SwapHoriz";
import Verified from "@mui/icons-material/Verified";

const HeroSection = styled("section")(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.default,
}));

const HeroContent = styled(Box)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(4),
}));

const StatCard = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  padding: theme.spacing(2),
}));

function Hero() {
  return (
    <HeroSection
      id="hero"
      // sx={{
      //   backgroundColor: "#171821",
      // }}
    >
      <Container>
        <Grid container alignItems="center" spacing={4}>
          {/* Left Section */}
          <Grid item xs={12} md={6}>
            <HeroContent>
              <Box
                my={3}
                p={1.5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: { xs: "auto", sm: "45%" },
                  borderRadius: 50,
                  backgroundColor:
                    "color-mix(in srgb, #4aa5f0, transparent 92%)",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#4aa5f0"
                  fontWeight="500"
                  display="flex"
                  alignItems="center"
                >
                  <Verified sx={{ mr: 1, fontFamily: "Roboto, sans-serif" }} />
                  {/* Changed to Wallet */}
                  Trusted, Reliable, & Secure
                </Typography>
              </Box>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                color="#fff"
                sx={{
                  fontWeight: "500",
                  fontFamily: "Faculty Glyphic, sans-serif",
                  fontSize: { xs: "2rem", sm: "3rem" },
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                Quantum Financial System For Everyone
              </Typography>
              <Typography
                variant="body1"
                color="#9998a0"
                sx={{
                  fontSize: 18,
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                QFS is a decentralized financial system designed to democratize
                money and eliminate central bank control.
              </Typography>
              <Box
                mt={4}
                display="flex"
                // flexDirection={{ xs: "column", sm: "row" }}
                gap={2}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    fontSize: 16,
                    background: "#4aa5f0",
                    borderRadius: "50px",
                    textTransform: "none",
                    padding: "10px 50px",
                    // width: "40%",
                    width: { xs: "100%", sm: "40%" },
                  }}
                  href="/register"
                >
                  Register
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  href="/login"
                  sx={{
                    fontSize: 16,
                    color: "#4aa5f0",
                    background: "#fff",
                    borderRadius: "50px",
                    textTransform: "none",
                    padding: "10px 50px",
                    // width: "40%",
                    width: { xs: "100%", sm: "40%" },
                  }}
                >
                  Login
                </Button>
              </Box>
            </HeroContent>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <img
                src="assets/img/hero-image.png"
                alt="Financial Success"
                style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Stats Section */}
        <Grid container spacing={4} mt={5}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              sx={{
                borderRadius: 5,
              }}
            >
              <Wallet fontSize="large" sx={{ color: "#4aa5f0" }} />
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  Wallets
                </Typography>
                <Typography color="textSecondary">
                  More than 10 Million Created
                </Typography>
              </CardContent>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              sx={{
                borderRadius: 5,
              }}
            >
              <Assets fontSize="large" sx={{ color: "#4aa5f0" }} />
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  Assets
                </Typography>
                <Typography color="textSecondary">
                  More than $500 Billion Secured
                </Typography>
              </CardContent>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              sx={{
                borderRadius: 5,
              }}
            >
              <Transactions fontSize="large" sx={{ color: "#4aa5f0" }} />
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  Transactions
                </Typography>
                <Typography color="textSecondary">
                  Billions Processed Monthly
                </Typography>
              </CardContent>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard
              sx={{
                borderRadius: 5,
              }}
            >
              <Exchanges fontSize="large" sx={{ color: "#4aa5f0" }} />
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  Exchanges
                </Typography>
                <Typography color="textSecondary">
                  Over 200+ Integrated Globally
                </Typography>
              </CardContent>
            </StatCard>
          </Grid>
        </Grid>
      </Container>
    </HeroSection>
  );
}

export default Hero;
