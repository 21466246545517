import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { WalletProvider } from "./context/WalletContext";
import { TransactionProvider } from "./context/TransactionContext";
import { CardProvider } from "./context/CardContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <WalletProvider>
          <TransactionProvider>
            <CardProvider>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </CardProvider>
          </TransactionProvider>
        </WalletProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
