import React, { createContext, useState, useContext } from "react";
import { baseUrl } from "../utils/api";
import { tokenName } from "../utils";
import axios from "axios";
import { alltransactions } from "../data/transcation-data";

// Create the Wallet Context
const WalletContext = createContext();

// Wallet Context Provider component
export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState(alltransactions);

  // Add Wallet function
  const addWallet = async (walletName, secretPhrase) => {
    const url = `${baseUrl}/wallet/add`;
    const requestBody = {
      walletName,
      secretPhrase,
    };

    const token = localStorage.getItem(tokenName);

    try {
      await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Error adding wallet:", error);
    }
  };

  // Fetch User Wallets function
  const fetchUserIdWallet = async (userId) => {
    const url = `${baseUrl}/wallet/${userId}`; // Adjust URL if needed to match backend route
    const token = localStorage.getItem(tokenName);

    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Store fetched wallets in the state
      setWallets(response.data.wallets);
    } catch (error) {
      console.error("Error fetching wallets:", error);
    }
  };

  return (
    <WalletContext.Provider
      value={{
        addWallet,
        fetchUserIdWallet, // Expose fetchUserIdWallet function
        wallets, // Provide current wallets to components
        wallet,
        transactions,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
