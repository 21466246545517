// Toast.js
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = ({ position = "top-right", autoClose = 1000 }) => (
  <ToastContainer
    position={position}
    autoClose={autoClose}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

// Custom functions for showing success and error messages
export const showSuccessToast = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
  });

export const showErrorToast = (message) =>
  toast.error(message, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
  });

export default Toast;
