import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createBrowserHistory } from "history";

import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
} from "@mui/material";
import Toast, { showErrorToast, showSuccessToast } from "../components/Toast";
import { useAuth } from "../context/AuthContext";
import theme from "../theme";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, loading, setLoading } = useAuth();
  const navigate = useNavigate();

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   try {
  //     await login(email, password);
  //     showSuccessToast("Login successful");
  //     setTimeout(() => navigate("/dashboard"), 1000);
  //   } catch (error) {
  //     console.error("Error during login:", error);
  //     showErrorToast(
  //       error.response?.data?.message || "An error occurred. Please try again."
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      showErrorToast("All fields are required");
      return;
    }

    if (!emailRegex.test(email)) {
      showErrorToast("Enter a valid email address");
      return;
    }

    setLoading(true);
    try {
      await login(email, password);
      showSuccessToast("Login successful");
      // navigate("/dashboard"); // Navigate immediately
      createBrowserHistory().push("/dashboard");
      createBrowserHistory().go(0);
    } catch (error) {
      console.error("Error during login:", error);
      showErrorToast(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="false"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#161d2c",
        backgroundColor: theme.palette.background.default,
        padding: 0,
      }}
    >
      <Toast />
      <Grid container sx={{ height: "100%" }}>
        {/* Left Section (Image) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <img
            src="/assets/img/signin-static.svg"
            alt="Login illustration"
            style={{ maxWidth: "80%", maxHeight: "300px" }}
          />
        </Grid>

        {/* Right Section (Form) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
              backgroundColor: "white",
              borderRadius: 5,
              boxShadow: 3,
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src="/assets/img/whiz-cyber-logo.png"
                alt="Open Financial System"
                style={{
                  maxWidth: "100%", // Ensures it adjusts to the container width
                  height: "auto", // Maintains aspect ratio
                  marginBottom: "20px", // Optional, space between image and form fields
                }}
                sx={{
                  width: { xs: "150px", sm: "200px", md: "250px" }, // Adjust the image size responsively
                }}
              />
            </Link>
            {/* <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            /> */}
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!emailRegex.test(email) && email.length > 0}
              helperText={
                !emailRegex.test(email) && email.length > 0
                  ? "Enter a valid email address"
                  : ""
              }
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              sx={{
                mt: 2,
                fontSize: "1rem", // Increase the font size for better visibility
                padding: "14px", // Adjust padding to make the button bigger
                height: "50px", // Set a fixed height for the button
                background: "#4aa5f0",
                color: "#ffffff",
                "&:hover": {
                  background: "#4aa5f0", // Darker hover color
                },
                transition: "all 0.3s ease", // Smooth transition for hover effect
                textTransform: "none",
                borderRadius: 50,
              }}
            >
              {loading ? "Loading..." : "Login"}
            </Button>
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="body3"
                color="textSecondary"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "400",
                }}
              >
                Don't have an account?{" "}
                <Link
                  to="/register"
                  style={{ textDecoration: "none", color: "#4aa5f0" }}
                >
                  Sign up here
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
