export const currencies = [
  "USD",
  "Doge",
  "XDC",
  "XLM",
  "MATIC",
  "XRP",
  "ETH",
  "LTC",
  "BTC",
  "ALGO",
];
