// ProtectedRoute.js

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { tokenName } from "../utils";

// function ProtectedRoute({ element: Component }) {
//   const token = localStorage.getItem(tokenName);

//   // If token exists, redirect to dashboard, else render the provided component
//   return token ? <Navigate to="/dashboard" /> : <Component />;
// }

// export default ProtectedRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { tokenName } from "../utils";
import { isTokenValid } from "../utils/auth"; // Utility for token validation

function ProtectedRoute({ element: Component }) {
  const token = localStorage.getItem(tokenName);

  return token && isTokenValid(token) ? (
    <Navigate to="/dashboard" />
  ) : (
    <Component />
  );
}

export default ProtectedRoute;
