import React, { useState } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import Layout from "../components/Layout";
import { useTransaction } from "../context/TransactionContext";
import { showErrorToast, showSuccessToast } from "../components/Toast";
import { currencies } from "../utils/currencies-data";

const Deposit = () => {
  const { loading, depositFunds } = useTransaction();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [paymentMethod, setPaymentMethod] = useState("Bank");
  const [bank, setBank] = useState("Bank Transfer");
  const [attachedFile, setAttachedFile] = useState("");

  const proceedHandle = async () => {
    if (!currency || !amount || !paymentMethod) {
      return showErrorToast("All inputs is required.");
    }
    setStep(2);
  };

  const handleDeposit = async () => {
    if (!attachedFile) {
      return showErrorToast("Please attach a file.");
    }

    const formData = new FormData();
    formData.append("amount", amount);
    formData.append("currency", currency);
    formData.append("paymentMethod", paymentMethod);
    formData.append("bank", bank);
    formData.append("attachedFile", attachedFile); // Ensure the key matches the backend's expectations

    try {
      await depositFunds(formData); // Ensure your backend can handle FormData
      showSuccessToast("Deposit Successful");
      setStep(3);
    } catch (err) {
      showErrorToast("Deposit Failed");
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 2,
          paddingTop: "50px",
          backgroundColor: "#21222d",
          minHeight: "100vh",
          color: "white",
        }}
      >
        <Box
          component="form"
          sx={{
            maxWidth: "800px",
            margin: "auto",
            my: 5,
          }}
        >
          {step === 1 ? (
            <Card
              sx={{
                backgroundColor: "#171821",
                borderRadius: 3,
                boxShadow: 3,
                padding: 3,
              }}
            >
              <CardContent>
                {/* Title */}
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "white",
                    marginBottom: 3,
                  }}
                >
                  Deposit Money
                </Typography>

                {/* Step Indicator */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#635bff",
                    marginBottom: 2,
                  }}
                >
                  Step: {step} of 3
                </Typography>

                {/* Instruction Text */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#9998a0",
                    fontSize: "14px",
                    marginBottom: 2,
                  }}
                >
                  You can deposit to your wallets using our popular payment
                  methods. Fill the details correctly & the amount you want to
                  deposit.
                </Typography>

                <TextField
                  select
                  label="Currency"
                  fullWidth
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Investment Amount Input */}
                <TextField
                  label="Amount"
                  type="number"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />

                <TextField
                  select
                  label="Payment Method"
                  fullWidth
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  sx={{
                    marginBottom: 5,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  <MenuItem value="Bank">Bank</MenuItem>
                  <MenuItem value="Manual">Manual</MenuItem>
                </TextField>

                <Button
                  type="button"
                  onClick={proceedHandle}
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    backgroundColor: "#635bff",
                    color: "white",
                    fontWeight: "500",
                    borderRadius: 50,
                    fontSize: 18,
                    padding: 1,
                    height: 50,
                    "&:hover": {
                      backgroundColor: "#635bff",
                    },
                    textTransform: "none",
                  }}
                >
                  {loading ? "Proceeding..." : "Proceed"}
                </Button>
              </CardContent>
            </Card>
          ) : step === 2 ? (
            <Card
              sx={{
                backgroundColor: "#171821",
                borderRadius: 3,
                boxShadow: 3,
                padding: 3,
              }}
            >
              <CardContent>
                {/* Title */}
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "white",
                    marginBottom: 3,
                  }}
                >
                  Deposit Money
                </Typography>

                {/* Step Indicator */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#635bff",
                    marginBottom: 2,
                  }}
                >
                  Step: {step} of 3
                </Typography>

                {/* Instruction Text */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#9998a0",
                    fontSize: "14px",
                    marginBottom: 2,
                  }}
                >
                  Fill in the details of your deposit with attached file. Take a
                  look over the details before confirmation.
                </Typography>

                <TextField
                  select
                  label="Select Bank"
                  fullWidth
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                </TextField>

                <TextField
                  type="file" // Changed to a file upload input
                  label="Attached File"
                  fullWidth
                  name="AttachedFile" // Ensure this matches the state key
                  InputLabelProps={{
                    shrink: true, // Keeps the label visible above the input
                  }}
                  // onChange={(e) => setAttachedFile(e.target.files[0])} // Update file state
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      setAttachedFile(e.target.files[0]);
                    } else {
                      setAttachedFile(null);
                    }
                  }}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />

                <Button
                  type="button"
                  onClick={handleDeposit}
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    backgroundColor: "#635bff",
                    color: "white",
                    fontWeight: "500",
                    borderRadius: 50,
                    fontSize: 18,
                    padding: 1,
                    height: 50,
                    "&:hover": {
                      backgroundColor: "#635bff",
                    },
                    textTransform: "none",
                  }}
                >
                  {loading ? "Sending..." : "Confirm"}
                </Button>
              </CardContent>
            </Card>
          ) : step === 3 ? (
            <Card
              sx={{
                backgroundColor: "#171821",
                borderRadius: 3,
                boxShadow: 3,
                padding: 3,
              }}
            >
              <CardContent>
                {/* Title */}
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Deposit Money
                </Typography>

                {/* Step Indicator */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#635bff",
                    marginBottom: 2,
                  }}
                >
                  Step: {step} of 3
                </Typography>

                {/* Instruction Text */}
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: "#9998a0",
                    fontSize: "14px",
                    marginBottom: 2,
                  }}
                >
                  Money has been successfully deposited to your wallet. You can
                  see the details under the transacton details.{" "}
                </Typography>

                <TextField
                  select
                  label="Bank"
                  fullWidth
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                </TextField>

                {/* Investment Amount Input */}
                <TextField
                  label="Amount"
                  type="number"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  sx={{
                    marginBottom: 3,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                />

                <TextField
                  select
                  label="Payment Method"
                  fullWidth
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  sx={{
                    marginBottom: 5,
                    "& .MuiInputLabel-root": { color: "white" },
                    "& .MuiOutlinedInput-root": {
                      color: "white",
                      "& fieldset": {
                        borderColor: "#444",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                    },
                  }}
                >
                  <MenuItem value="Bank">Bank</MenuItem>
                  <MenuItem value="Manual">Manual</MenuItem>
                </TextField>

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    backgroundColor: "#635bff",
                    color: "white",
                    fontWeight: "500",
                    borderRadius: 50,
                    fontSize: 18,
                    padding: 1,
                    height: 50,
                    "&:hover": {
                      backgroundColor: "#635bff",
                    },
                    textTransform: "none",
                  }}
                >
                  {loading ? "Proceeding..." : "Proceed"}
                </Button>
              </CardContent>
            </Card>
          ) : null}
        </Box>
      </Box>
    </Layout>
  );
};

export default Deposit;
