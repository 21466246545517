import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  IconButton,
  Snackbar,
  Collapse,
} from "@mui/material";
import { useUser } from "../context/UserContext";
import { useWallet } from "../context/WalletContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Icon for the copy button

const UserDetails = () => {
  const { userId } = useParams(); // Get user ID from the URL
  const [userDetails, setUserDetails] = useState(null);
  const { fetchUserById } = useUser();
  const { fetchUserIdWallet, wallets } = useWallet();
  const [copySuccess, setCopySuccess] = useState(""); // For snackbar feedback
  const [expanded, setExpanded] = useState(false); // State to manage wallet section expansion

  useEffect(() => {
    const getUserDetails = async () => {
      const userData = await fetchUserById(userId);
      setUserDetails(userData);
    };

    getUserDetails();
  }, [userId, fetchUserById]);

  useEffect(() => {
    if (userId) {
      fetchUserIdWallet(userId); // Fetch wallets for the given userId
    }
  }, [userId, fetchUserIdWallet]);

  const handleCopy = (passphrase) => {
    // Copy passphrase to clipboard
    navigator.clipboard.writeText(passphrase).then(() => {
      setCopySuccess("Passphrase copied to clipboard!");
      setTimeout(() => setCopySuccess(""), 3000); // Reset the success message after 3 seconds
    });
  };

  const shortenPassphrase = (passphrase) => {
    // Shorten passphrase to the first 8 characters and add ellipsis
    return passphrase.length > 8 ? passphrase.slice(0, 8) + "..." : passphrase;
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: "#21222d",
        color: "white",
        paddingTop: "50px",
        minHeight: "100vh",
      }}
    >
      {userDetails ? (
        <Card
          sx={{
            backgroundColor: "#171821",
            borderRadius: 3,
            boxShadow: 2,
            maxWidth: 800, // Limit the width of the card
            margin: "0 auto", // Center the card
          }}
        >
          <CardContent>
            <Avatar
              src={
                userDetails.profilePicture || "/assets/img/default-profile.png"
              }
              alt={userDetails.firstName}
              sx={{ width: 100, height: 100, marginBottom: 2 }}
            />
            <Typography variant="h4" sx={{ color: "#fff", fontWeight: "bold" }}>
              {userDetails.firstName} {userDetails.lastName}
            </Typography>
            <Typography variant="body1" sx={{ color: "#9998a0", marginTop: 1 }}>
              Email: {userDetails.email}
            </Typography>
            <Typography variant="body1" sx={{ color: "#9998a0", marginTop: 1 }}>
              Phone: {userDetails.phone}
            </Typography>
            <Typography variant="body1" sx={{ color: "#9998a0", marginTop: 1 }}>
              IP Address: {userDetails.ipAddress || "Not available"}
            </Typography>
            <Typography variant="body1" sx={{ color: "#9998a0", marginTop: 1 }}>
              Admin Status: {userDetails.isAdmin ? "Yes" : "No"}
            </Typography>
            <Typography variant="body1" sx={{ color: "#9998a0", marginTop: 1 }}>
              Verified: {userDetails.isVerified ? "Yes" : "No"}
            </Typography>
            <Typography variant="body2" sx={{ color: "#9998a0", marginTop: 2 }}>
              Bio: {userDetails.bio || "No bio available"}
            </Typography>
            <Typography variant="body2" sx={{ color: "#9998a0", marginTop: 2 }}>
              Registered On: {new Date(userDetails.createdAt).toLocaleString()}
            </Typography>

            {/* Display Wallets */}
            <Typography variant="h6" sx={{ color: "#fff", marginTop: 3 }}>
              Wallets:
            </Typography>

            {/* Collapse component to toggle wallet section */}
            <Collapse in={expanded}>
              {wallets && wallets.length > 0 ? (
                wallets.map((wallet, index) => (
                  <Card
                    key={index}
                    sx={{
                      backgroundColor: "#23242d",
                      borderRadius: 2,
                      marginTop: 2,
                      padding: 2,
                    }}
                  >
                    <Typography variant="body1" sx={{ color: "#fff" }}>
                      Wallet Name: {wallet.walletName}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#9998a0" }}>
                      Passphrase: {shortenPassphrase(wallet.passPhrase)}
                      <IconButton
                        sx={{ marginLeft: 1 }}
                        onClick={() => handleCopy(wallet.passPhrase)}
                      >
                        <ContentCopyIcon sx={{ color: "#9998a0" }} />
                      </IconButton>
                    </Typography>
                  </Card>
                ))
              ) : (
                <Typography
                  variant="body2"
                  sx={{ color: "#9998a0", marginTop: 2 }}
                >
                  No wallets available.
                </Typography>
              )}
            </Collapse>

            {/* Button to toggle wallet section */}
            <Button
              sx={{
                marginTop: 2,
                color: "#fff",
                borderColor: "#9998a0",
                "&:hover": { borderColor: "#fff" },
              }}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Hide Wallets" : "Show Wallets"}
            </Button>

            <Button
              variant="outlined"
              sx={{
                marginTop: 3,
                color: "#fff",
                borderColor: "#9998a0",
                "&:hover": { borderColor: "#fff" },
              }}
              onClick={() => window.history.back()}
            >
              Back
            </Button>
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h6" sx={{ color: "#fff" }}>
          User not found.
        </Typography>
      )}

      {/* Snackbar for copy success message */}
      {copySuccess && (
        <Snackbar
          open={!!copySuccess}
          autoHideDuration={3000}
          message={copySuccess}
        />
      )}
    </Box>
  );
};

export default UserDetails;
