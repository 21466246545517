// import React from "react";

// function Contact() {
//   return (
//     <section id="contact" className="contact section light-background">
//       {/* Section Title */}
//       <div className="container section-title" data-aos="fade-up">
//         <h2>Contact Us</h2>
//         <p>
//           Have questions or need support? We're here to help you understand the
//           future of finance. Get in touch with us today.
//         </p>
//       </div>
//       {/* End Section Title */}

//       <div className="container" data-aos="fade-up" data-aos-delay="100">
//         <div className="row g-4 g-lg-5">
//           <div className="col-lg-5">
//             <div className="info-box" data-aos="fade-up" data-aos-delay="200">
//               <h3>Contact Information</h3>
//               <p>
//                 Whether you're a beginner or an experienced user, we’re here to
//                 guide you on your journey with open finance. Reach out if you
//                 have any questions about our platform, services, or the open
//                 financial system in general.
//               </p>

//               <div className="info-item" data-aos="fade-up" data-aos-delay="300">
//                 <div className="icon-box">
//                   <i className="bi bi-geo-alt"></i>
//                 </div>
//                 <div className="content">
//                   <h4>Our Location</h4>
//                   <p>123 Open Finance St.</p>
//                   <p>Blockchain City, BC 98765</p>
//                 </div>
//               </div>

//               <div className="info-item" data-aos="fade-up" data-aos-delay="400">
//                 <div className="icon-box">
//                   <i className="bi bi-telephone"></i>
//                 </div>
//                 <div className="content">
//                   <h4>Phone Number</h4>
//                   <p>+1 555 123 4567</p>
//                 </div>
//               </div>

//               <div className="info-item" data-aos="fade-up" data-aos-delay="500">
//                 <div className="icon-box">
//                   <i className="bi bi-envelope"></i>
//                 </div>
//                 <div className="content">
//                   <h4>Email Address</h4>
//                   <p>support@openfinance.com</p>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-7">
//             <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
//               <h3>Get In Touch</h3>
//               <p>
//                 Whether you're looking for assistance, have feedback, or just
//                 want to learn more about our platform, we’d love to hear from
//                 you.
//               </p>

//               <form
//                 action="forms/contact.php"
//                 method="post"
//                 className="php-email-form"
//                 data-aos="fade-up"
//                 data-aos-delay="200"
//               >
//                 <div className="row gy-4">
//                   <div className="col-md-6">
//                     <input
//                       type="text"
//                       name="name"
//                       className="form-control"
//                       placeholder="Name"
//                       required
//                     />
//                   </div>

//                   <div className="col-md-6">
//                     <input
//                       type="phone"
//                       className="form-control"
//                       name="phone"
//                       placeholder="Phone"
//                       required
//                     />
//                   </div>

//                   <div className="col-12">
//                     <input
//                       type="email"
//                       className="form-control"
//                       name="email"
//                       placeholder="Email"
//                       required
//                     />
//                   </div>

//                   <div className="col-12">
//                     <textarea
//                       className="form-control"
//                       name="message"
//                       rows="6"
//                       placeholder="Message"
//                       required
//                     ></textarea>
//                   </div>

//                   <div className="col-12 text-center">
//                     <div className="loading">Loading...</div>
//                     <div className="error-message"></div>
//                     <div className="sent-message">
//                       Your message has been sent. Thank you for reaching out!
//                     </div>

//                     <button type="submit" className="btn">
//                       Send Message
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Contact;

// import React, { useState } from "react";
// import axios from "axios";
// import Toast, { showSuccessToast, showErrorToast } from "./Toast";

// function Contact() {
//   const [formData, setFormData] = useState({
//     name: "",
//     phone: "",
//     email: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post("/api/contact", formData);

//       if (response.status === 200) {
//         // Show success toast message
//         showSuccessToast(
//           "Your message has been sent. Thank you for reaching out!"
//         );

//         // Reset form fields
//         setFormData({
//           name: "",
//           phone: "",
//           email: "",
//           message: "",
//         });
//       }
//     } catch (error) {
//       // Show error toast message in case of an error
//       showErrorToast("Failed to send message. Please try again.");
//       console.error("Form submission error:", error);
//     }
//   };

//   return (
//     <section id="contact" className="contact section light-background">
//       <Toast /> {/* Include the Toast component for toast notifications */}
//       <div className="container section-title" data-aos="fade-up">
//         <h2 className="text-white">Contact Us</h2>
//         <p>
//           Have questions or need support? We're here to help you understand the
//           future of finance. Get in touch with us today.
//         </p>
//       </div>
//       <div className="container" data-aos="fade-up" data-aos-delay="100">
//         <div className="row g-4 g-lg-5">
//           <div className="col-lg-5">
//             <div className="info-box" data-aos="fade-up" data-aos-delay="200">
//               <h3>Contact Information</h3>
//               <p>
//                 Whether you're a beginner or an experienced user, we’re here to
//                 guide you on your journey with open finance system. Reach out if
//                 you have any questions about our platform, services, or the open
//                 financial system in general.
//               </p>
//               <div
//                 className="info-item"
//                 data-aos="fade-up"
//                 data-aos-delay="300"
//               >
//                 <div className="icon-box">
//                   <i className="bi bi-geo-alt"></i>
//                 </div>
//                 <div className="content">
//                   <h4>Our Location</h4>
//                   <p>123 Open Finance St.</p>
//                   <p>Blockchain City, BC 98765</p>
//                 </div>
//               </div>

//               <div
//                 className="info-item"
//                 data-aos="fade-up"
//                 data-aos-delay="400"
//               >
//                 <div className="icon-box">
//                   <i className="bi bi-telephone"></i>
//                 </div>
//                 <div className="content">
//                   <h4>Phone Number</h4>
//                   <p>+1 555 123 4567</p>
//                 </div>
//               </div>

//               <div
//                 className="info-item"
//                 data-aos="fade-up"
//                 data-aos-delay="500"
//               >
//                 <div className="icon-box">
//                   <i className="bi bi-envelope"></i>
//                 </div>
//                 <div className="content">
//                   <h4>Email Address</h4>
//                   <p>info@quantumfinancialsystem.com</p>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-7">
//             <div
//               className="contact-form"
//               data-aos="fade-up"
//               data-aos-delay="300"
//             >
//               <h3 className="text-white">Get In Touch</h3>
//               <p className="text-gray">
//                 Whether you're looking for assistance, have feedback, or just
//                 want to learn more about our platform, we’d love to hear from
//                 you.
//               </p>

//               <form
//                 onSubmit={handleSubmit}
//                 className="php-email-form"
//                 data-aos="fade-up"
//                 data-aos-delay="200"
//               >
//                 <div className="row gy-4">
//                   <div className="col-md-6">
//                     <input
//                       type="text"
//                       name="name"
//                       className="form-control"
//                       placeholder="Name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>

//                   <div className="col-md-6">
//                     <input
//                       type="tel"
//                       name="phone"
//                       className="form-control"
//                       placeholder="Phone"
//                       value={formData.phone}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>

//                   <div className="col-12">
//                     <input
//                       type="email"
//                       name="email"
//                       className="form-control"
//                       placeholder="Email"
//                       value={formData.email}
//                       onChange={handleChange}
//                       required
//                     />
//                   </div>

//                   <div className="col-12">
//                     <textarea
//                       className="form-control"
//                       name="message"
//                       rows="6"
//                       placeholder="Message"
//                       value={formData.message}
//                       onChange={handleChange}
//                       required
//                     ></textarea>
//                   </div>

//                   <div className="col-12 text-center">
//                     <button type="submit" className="btn">
//                       Send Message
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Contact;

import React, { useState } from "react";
import axios from "axios";
import Toast, { showSuccessToast, showErrorToast } from "./Toast";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import theme from "../theme";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/contact", formData);

      if (response.status === 200) {
        // Show success toast message
        showSuccessToast(
          "Your message has been sent. Thank you for reaching out!"
        );

        // Reset form fields
        setFormData({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      // Show error toast message in case of an error
      showErrorToast("Failed to send message. Please try again.");
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box
      id="contact"
      sx={{
        // backgroundColor: "#171821",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Toast /> {/* Include the Toast component for toast notifications */}
      <Container sx={{ py: 6 }} maxWidth="lg">
        <Typography
          variant="h4"
          gutterBottom
          color="white"
          sx={{
            fontWeight: "500",
            fontFamily: "Faculty Glyphic, sans-serif",
          }}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" color="#9998a0" mb={2}>
          Have questions or need support? We're here to help you understand the
          future of finance. Get in touch with us today.
        </Typography>

        <Grid container spacing={4}>
          {/* Contact Information */}
          <Grid item xs={12} md={5}>
            <Paper
              elevation={3}
              sx={{ p: 3, backgroundColor: "#4aa5f0", borderRadius: 5 }}
            >
              <Typography
                variant="h5"
                color="#fff"
                gutterBottom
                sx={{
                  fontFamily: "Faculty Glyphic, sans-serif",
                  fontWeight: "600",
                }}
              >
                Contact Information
              </Typography>
              <Typography color="#fff" gutterBottom mb={5}>
                Whether you're a beginner or an experienced user, we’re here to
                guide you on your journey with the open finance system. Reach
                out if you have any questions about our platform, services, or
                the open financial system in general.
              </Typography>

              {/* <Box
                sx={{ display: "flex", alignItems: "center", color: "#fff" }}
              >
                <IconButton sx={{ mr: 2, color: "#fff" }}>
                  <Send />
                </IconButton>
                g<Box>
                  <Typography variant="body1">Our Location</Typography>
                  <Typography variant="body2">123 Open Finance St.</Typography>
                  <Typoraphy variant="body2">
                    Blockchain City, BC 98765
                  </Typography>
                </Box>
              </Box> */}

              <Divider sx={{ my: 2 }} />

              <Box
                sx={{ display: "flex", alignItems: "center", color: "#fff" }}
              >
                <IconButton sx={{ mr: 2, color: "#fff" }}>
                  <Send />
                </IconButton>
                <Box>
                  <Typography variant="body1">Phone Number</Typography>
                  <Typography variant="body2">+1 555 123 4567</Typography>
                </Box>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Box
                sx={{ display: "flex", alignItems: "center", color: "#fff" }}
              >
                <IconButton sx={{ mr: 2, color: "#fff" }}>
                  <Send />
                </IconButton>
                <Box>
                  <Typography variant="body1">Email Address</Typography>
                  <Typography variant="body2">
                    {/* support@quantumfinancialsystemonline.net */}
                    qfscustomeronlineservice@gmail.com
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Contact Form */}
          <Grid item xs={12} md={7}>
            <Paper elevation={3} sx={{ p: 3, borderRadius: 5 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontFamily: "Faculty Glyphic, sans-serif",
                  fontWeight: "600",
                }}
              >
                Get In Touch
              </Typography>
              <Typography variant="body1" color="#9998a0" mb={3}>
                Whether you're looking for assistance, have feedback, or just
                want to learn more about our platform, we’d love to hear from
                you.
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      variant="outlined"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Message"
                      variant="outlined"
                      multiline
                      rows={6}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: 16,
                        borderRadius: 50,
                        padding: "10px 50px",
                        textTransform: "none",
                        backgroundColor: "#4aa5f0",
                        "&:hover": {
                          backgroundColor: "#4aa5f0",
                        },
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contact;
