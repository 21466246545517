import { useState, useEffect } from "react";
import axios from "axios";
import { Link as ScrollLink } from "react-scroll";
import { showErrorToast, showSuccessToast } from "./Toast";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import {
  Email,
  LocationOn,
  Twitter,
  Facebook,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";
import theme from "../theme";

function Footer() {
  const [email, setEmail] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    if (toggle) {
      document.body.classList.add("mobile-nav-active");
    } else {
      document.body.classList.remove("mobile-nav-active");
    }
    return () => {
      document.body.classList.remove("mobile-nav-active");
    };
  }, [toggle]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setToggle(!toggle);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/newletter", email);
      if (response.status === 200) {
        showSuccessToast(
          "Your message has been sent. Thank you for reaching out!"
        );
        setEmail("");
      }
    } catch (error) {
      showErrorToast("Failed to send message. Please try again.");
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box
      component="footer"
      id="footer"
      sx={{
        fontFamily: "Roboto, sans-serif",
        color: "#ffffff",
        py: 5,
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Container>
        <Grid container spacing={4}>
          {/* About Section */}
          <Grid item xs={12} md={4}>
            <Box>
              <ScrollLink
                to="hero"
                spy={true}
                smooth={true}
                offset={-70}
                duration={100}
                className={`cursor-pointer ${
                  activeLink === "hero" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("hero")}
              >
                <img src="assets/img/whiz-cyber-logo.png" alt="" />
              </ScrollLink>
              <Typography variant="body1" mt={2} sx={{ fontSize: 16 }}>
                Quantum Financial System: The Biggest Financial Revolutionary
                System Developed to Unseat the World's Banking System By
                Eliminating Control Of Money By Cabals.
              </Typography>
              {/* Social Media Links */}
              {/* <Box mt={2}>
                <IconButton href="">
                  <Twitter />
                </IconButton>
                <IconButton href="">
                  <Facebook />
                </IconButton>
                <IconButton href="">
                  <Instagram />
                </IconButton>
                <IconButton href="">
                  <LinkedIn />
                </IconButton>
              </Box> */}
            </Box>
          </Grid>

          {/* Useful Links */}
          <Grid item xs={12} md={2}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Faculty Glyphic, sans-serif",
                fontWeight: "500",
              }}
            >
              About Us
            </Typography>
            <Box component="ul" sx={{ listStyle: "none", p: 0, mt: 2 }}>
              {["hero", "about", "contact", "services"].map((link) => (
                <Box component="li" key={link} sx={{ mt: 2, fontSize: 16 }}>
                  <ScrollLink
                    to={link}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={100}
                    className={`cursor-pointer`}
                    onClick={() => handleLinkClick(link)}
                    style={{ cursor: "pointer" }}
                  >
                    {link.charAt(0).toUpperCase() +
                      link.slice(1).replace("-", " ")}
                  </ScrollLink>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Faculty Glyphic, sans-serif",
                fontWeight: "500",
              }}
            >
              Contact Info
            </Typography>
            <Box component="ul" sx={{ listStyle: "none", p: 0, mt: 2 }}>
              <li>
                <Box display="flex" alignItems="center" gap={1}>
                  {/* <Box
                    sx={{
                      bgcolor: "#4aa5f0",
                      color: "white",
                      width: 38,
                      height: 32,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Email sx={{ fontSize: 18 }} />
                  </Box> */}
                  <Typography variant="body1" sx={{ fontSize: 13 }}>
                    {/* support@quantumfinancialsystemonline.net */}
                    qfscustomeronlineservice@gmail.com
                  </Typography>
                </Box>
              </li>
              {/* <li>
                <Box display="flex" alignItems="center" gap={1} marginTop={2}>
                  <Box
                    sx={{
                      bgcolor: "#4aa5f0",
                      color: "white",
                      width: 38,
                      height: 32,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LocationOn sx={{ fontSize: 18 }} />
                  </Box>
                  <Typography variant="body1" sx={{ fontSize: 16 }}>
                    21 King Street Melbourne, 3000, United State
                  </Typography>
                </Box>
              </li> */}
            </Box>
          </Grid>

          {/* Newsletter */}
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Faculty Glyphic, sans-serif",
                fontWeight: "500",
              }}
            >
              Sign up for Newsletter
            </Typography>
            <Box component="form" onSubmit={handleSubmit} mt={2}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                sx={{
                  backgroundColor: "#fff",
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  fontSize: 16,
                  borderRadius: 10,
                  color: "#ffff",
                  height: "50px",
                  textTransform: "none",
                  backgroundColor: "#4aa5f0",
                  "&:hover": {
                    backgroundColor: "#4aa5f0",
                  },
                }}
                disabled={loading}
              >
                {loading ? "Subscribing..." : "Subscribe"}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="center" mt={4}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
            }}
          >
            © 2024 <strong>Quantum Financial System</strong>. All Rights
            Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
