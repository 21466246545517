// import React, { useState, useEffect } from "react";
// import { Link } from "react-scroll";
// import { Link as RouterLink } from "react-router-dom";

// function Header() {
//   const [toggle, setToggle] = useState(false);

//   useEffect(() => {
//     // Add or remove class based on toggle state
//     if (toggle) {
//       document.body.classList.add("mobile-nav-active");
//     } else {
//       document.body.classList.remove("mobile-nav-active");
//     }

//     // Clean up function to remove class when component unmounts
//     return () => {
//       document.body.classList.remove("mobile-nav-active");
//     };
//   }, [toggle]);
//   return (
//     <header id="header" className="header d-flex align-items-center fixed-top">
//       <div className="header-container container-xl position-relative d-flex align-items-center justify-content-between">
//         <Link
//           href="/"
//           className="logo d-flex align-items-center me-auto me-xl-0"
//         >
//           {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
//           {/* <!-- <img src="assets/img/logo.png" alt=""> --> */}
//           <h1 className="sitename">QFS</h1>
//         </Link>

//         <nav id="navmenu" className="navmenu">
//           <ul>
//             <li>
//               <Link
//                 to="hero"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className="active"
//                 onClick={() => setToggle(!toggle)}
//               >
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="about"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className="active"
//                 onClick={() => setToggle(!toggle)}
//               >
//                 About
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="services"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className="active"
//                 onClick={() => setToggle(!toggle)}
//               >
//                 Services
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="faq"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className="active"
//                 onClick={() => setToggle(!toggle)}
//               >
//                 Faq
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="contact"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className="active"
//                 onClick={() => setToggle(!toggle)}
//               >
//                 Contact
//               </Link>
//             </li>
//             {/* <RouterLink className="btn-getstarted" to="/login">
//               Login
//             </RouterLink> */}
//           </ul>
//           <i
//             onClick={() => setToggle(!toggle)}
//             className={`mobile-nav-toggle d-xl-none bi bi-${
//               toggle ? "x" : "list"
//             }`}
//           ></i>
//         </nav>
//         <Link className="btn-getstarted" href="/login">
//           Get Started
//         </Link>
//       </div>
//     </header>
//   );
// }

// export default Header;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-scroll";
// import { Link as RouterLink } from "react-router-dom";

// function Header() {
//   const [toggle, setToggle] = useState(false);
//   const [activeLink, setActiveLink] = useState(""); // State for active link

//   useEffect(() => {
//     // Add or remove class based on toggle state
//     if (toggle) {
//       document.body.classList.add("mobile-nav-active");
//     } else {
//       document.body.classList.remove("mobile-nav-active");
//     }

//     // Clean up function to remove class when component unmounts
//     return () => {
//       document.body.classList.remove("mobile-nav-active");
//     };
//   }, [toggle]);

//   const handleLinkClick = (link) => {
//     setActiveLink(link); // Set the clicked link as active
//     setToggle(!toggle); // Toggle the mobile menu
//   };

//   return (
//     <header id="header" className="header d-flex align-items-center fixed-top">
//       <div className="header-container container-xl position-relative d-flex align-items-center justify-content-between">
//         <Link
//           to="hero"
//           spy={true}
//           smooth={true}
//           offset={-70}
//           duration={100}
//           className={`logo d-flex align-items-center me-auto me-xl-0 ${
//             activeLink === "hero" ? "active" : ""
//           }`}
//           onClick={() => setActiveLink("hero")}
//         >
//           {/* <h1 className="sitename">QFS</h1> */}
//           <img src="assets/img/whiz-cyber-logo.png" alt="" className="mb-1" />
//         </Link>

//         <nav id="navmenu" className="navmenu">
//           <ul>
//             <li>
//               <Link
//                 to="hero"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className={activeLink === "hero" ? "active" : ""}
//                 onClick={() => handleLinkClick("hero")}
//               >
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="about"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className={activeLink === "about" ? "active" : ""}
//                 onClick={() => handleLinkClick("about")}
//               >
//                 About
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="services"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className={activeLink === "services" ? "active" : ""}
//                 onClick={() => handleLinkClick("services")}
//               >
//                 Services
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="faq"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className={activeLink === "faq" ? "active" : ""}
//                 onClick={() => handleLinkClick("faq")}
//               >
//                 Faq
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="contact"
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={100}
//                 className={activeLink === "contact" ? "active" : ""}
//                 onClick={() => handleLinkClick("contact")}
//               >
//                 Contact
//               </Link>
//             </li>
//           </ul>
//           <i
//             onClick={() => setToggle(!toggle)}
//             className={`mobile-nav-toggle d-xl-none bi bi-${
//               toggle ? "x" : "list"
//             }`}
//           ></i>
//         </nav>
//         <RouterLink className="btn-getstarted" to="/login">
//           Get Started
//         </RouterLink>
//       </div>
//     </header>
//   );
// }

// export default Header;

// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Box,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Button,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link as ScrollLink } from "react-scroll";
// import { Link as RouterLink } from "react-router-dom";

// function Header() {
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [activeLink, setActiveLink] = useState(""); // Track active link

//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//     setMobileMenuOpen(false);
//   };

//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!mobileMenuOpen);
//   };

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         backgroundColor: "#21222d",
//         boxShadow: "none",
//       }}
//     >
//       <Toolbar>
//         <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
//           <ScrollLink
//             to="hero"
//             spy={true}
//             smooth={true}
//             offset={-70}
//             duration={100}
//             onClick={() => handleLinkClick("hero")}
//             style={{
//               textDecoration: "none",
//               cursor: "pointer",
//               color: activeLink === "hero" ? "#4aa5f0" : "#fff",
//             }}
//           >
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               <img
//                 src="assets/img/whiz-cyber-logo.png"
//                 alt="Logo"
//                 className="mb-1"
//                 width="100"
//               />
//             </Box>
//           </ScrollLink>
//         </Box>

//         <Box
//           sx={{
//             display: { xs: "none", md: "flex" },
//             gap: 3,
//             alignItems: "center",
//           }}
//         >
//           {["hero", "about", "services", "faq", "contact"].map((link) => (
//             <ScrollLink
//               key={link}
//               to={link}
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={100}
//               onClick={() => handleLinkClick(link)}
//               style={{
//                 textDecoration: "none",
//                 cursor: "pointer",
//                 color: activeLink === link ? "#4aa5f0" : "#fff",
//               }}
//             >
//               {link.charAt(0).toUpperCase() + link.slice(1)}
//             </ScrollLink>
//           ))}
//           <RouterLink to="/login" style={{ textDecoration: "none" }}>
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{
//                 fontSize: 16,
//                 background: "#4aa5f0",
//                 borderRadius: "50px",
//                 textTransform: "none",
//               }}
//             >
//               Get Started
//             </Button>
//           </RouterLink>
//         </Box>

//         <IconButton
//           edge="end"
//           color="inherit"
//           aria-label="menu"
//           onClick={toggleMobileMenu}
//           sx={{ display: { xs: "block", md: "none" } }}
//         >
//           <MenuIcon />
//         </IconButton>
//       </Toolbar>

//       {/* Mobile Menu Drawer */}
//       <Drawer
//         anchor="right"
//         open={mobileMenuOpen}
//         onClose={toggleMobileMenu}
//         sx={{ display: { md: "none" } }}
//       >
//         <List sx={{ width: 250 }}>
//           {["hero", "about", "services", "faq", "contact"].map((link) => (
//             <ListItem
//               button
//               key={link}
//               onClick={() => handleLinkClick(link)}
//               sx={{ textTransform: "capitalize" }}
//             >
//               <ListItemText
//                 primary={link.charAt(0).toUpperCase() + link.slice(1)}
//                 primaryTypographyProps={{
//                   color: activeLink === link ? "primary" : "textPrimary",
//                 }}
//               />
//             </ListItem>
//           ))}
//           <ListItem button>
//             <RouterLink
//               to="/login"
//               style={{
//                 textDecoration: "none",
//                 width: "100%",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 sx={{
//                   fontSize: 16,
//                   background: "#4aa5f0",
//                   borderRadius: "50px",
//                   textTransform: "none",
//                 }}
//               >
//                 Get Started
//               </Button>
//             </RouterLink>
//           </ListItem>
//         </List>
//       </Drawer>
//     </AppBar>
//   );
// }

// export default Header;

// import React, { useState } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Box,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Button,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link as ScrollLink, scroller } from "react-scroll"; // Import scroller
// import { Link as RouterLink } from "react-router-dom";

// function Header() {
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [activeLink, setActiveLink] = useState(""); // Track active link

//   // Scroll and close the drawer
//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//     setMobileMenuOpen(false); // Close mobile menu
//     scroller.scrollTo(link, {
//       duration: 500,
//       smooth: true,
//       offset: -70, // Adjust for fixed header height
//     });
//   };

//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!mobileMenuOpen);
//   };

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         backgroundColor: "#171821",
//         boxShadow: "none",
//       }}
//     >
//       <Toolbar className="7">
//         {/* Logo */}
//         <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
//           <ScrollLink
//             to="hero"
//             spy={true}
//             smooth={true}
//             offset={-70} // Adjust for fixed header height
//             duration={500}
//             onClick={() => handleLinkClick("hero")}
//             style={{
//               textDecoration: "none",
//               cursor: "pointer",
//               color: activeLink === "hero" ? "#4aa5f0" : "#fff",
//             }}
//           >
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               <img
//                 src="assets/img/whiz-cyber-logo.png"
//                 alt="Logo"
//                 width={100}
//               />
//             </Box>
//           </ScrollLink>
//         </Box>

//         {/* Desktop Links */}
//         <Box
//           sx={{
//             display: { xs: "none", md: "flex" },
//             gap: 3,
//             alignItems: "center",
//             height: "70px",
//           }}
//         >
//           {/* Update 'hero' text to 'Home' */}
//           {[
//             { link: "hero", text: "Home" },
//             { link: "about", text: "About" },
//             { link: "services", text: "Services" },
//             { link: "faq", text: "FAQ" },
//             { link: "contact", text: "Contact" },
//           ].map(({ link, text }) => (
//             <ScrollLink
//               key={link}
//               to={link}
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={500}
//               onClick={() => handleLinkClick(link)}
//               style={{
//                 fontSize: 16,
//                 textDecoration: "none",
//                 cursor: "pointer",
//                 color: activeLink === link ? "#4aa5f0" : "#fff",
//               }}
//             >
//               {text}
//             </ScrollLink>
//           ))}
//           <RouterLink to="/login" style={{ textDecoration: "none" }}>
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{
//                 fontSize: 16,
//                 background: "#4aa5f0",
//                 borderRadius: "50px",
//                 textTransform: "none",
//                 padding: "7px 40px",
//               }}
//             >
//               login
//             </Button>
//           </RouterLink>
//         </Box>

//         {/* Mobile Menu Icon */}
//         <IconButton
//           edge="end"
//           color="inherit"
//           aria-label="menu"
//           onClick={toggleMobileMenu}
//           sx={{ display: { xs: "block", md: "none" } }}
//         >
//           <MenuIcon />
//         </IconButton>
//       </Toolbar>

//       {/* Mobile Menu Drawer */}
//       <Drawer
//         anchor="right"
//         open={mobileMenuOpen}
//         onClose={toggleMobileMenu}
//         sx={{
//           display: { md: "none" },
//           "& .MuiDrawer-paper": {
//             backgroundColor: "#171821", // Set background color
//             color: "#fff", // Ensure text contrast
//           },
//         }}
//       >
//         <List sx={{ width: 250 }}>
//           {/* Update 'hero' text to 'Home' */}
//           {[
//             { link: "hero", text: "Home" },
//             { link: "about", text: "About" },
//             { link: "services", text: "Services" },
//             { link: "faq", text: "FAQ" },
//             { link: "contact", text: "Contact" },
//           ].map(({ link, text }) => (
//             <ListItem
//               button
//               key={link}
//               onClick={() => handleLinkClick(link)} // Ensure scrolling and drawer close
//               sx={{
//                 textTransform: "capitalize",
//                 "&:hover": { backgroundColor: "#222831" }, // Add hover effect
//               }}
//             >
//               <ListItemText
//                 primary={text}
//                 primaryTypographyProps={{
//                   color: activeLink === link ? "primary" : "inherit",
//                 }}
//               />
//             </ListItem>
//           ))}
//           <ListItem>
//             <RouterLink
//               to="/login"
//               style={{
//                 textDecoration: "none",
//                 width: "100%",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 sx={{
//                   fontSize: 16,
//                   backgroundColor: "#4aa5f0",
//                   borderRadius: "50px",
//                   textTransform: "none",
//                   "&:hover": {
//                     backgroundColor: "#006bb3", // Slightly darker hover color
//                   },
//                 }}
//               >
//                 login
//               </Button>
//             </RouterLink>
//           </ListItem>
//         </List>
//       </Drawer>
//     </AppBar>
//   );
// }

// export default Header;

// import React, { useState, useEffect } from "react";
// import {
//   AppBar,
//   Toolbar,
//   Box,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Button,
// } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link as ScrollLink, scroller } from "react-scroll";
// import { Link as RouterLink } from "react-router-dom";

// function Header() {
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [activeLink, setActiveLink] = useState("hero"); // Set initial active link to "hero"

//   // Scroll to the active link on page load
//   useEffect(() => {
//     scroller.scrollTo(activeLink, {
//       duration: 500,
//       smooth: true,
//       offset: -70, // Adjust for fixed header height
//     });
//   }, [activeLink]);

//   const handleLinkClick = (link) => {
//     setActiveLink(link);
//     setMobileMenuOpen(false); // Close mobile menu
//     scroller.scrollTo(link, {
//       duration: 500,
//       smooth: true,
//       offset: -70,
//     });
//   };

//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!mobileMenuOpen);
//   };

//   return (
//     <AppBar
//       position="fixed"
//       sx={{
//         backgroundColor: "#171821",
//         boxShadow: "none",
//       }}
//     >
//       <Toolbar>
//         <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
//           <ScrollLink
//             to="hero"
//             spy={true}
//             smooth={true}
//             offset={-70}
//             duration={500}
//             onClick={() => handleLinkClick("hero")}
//             style={{
//               textDecoration: "none",
//               cursor: "pointer",
//               color: activeLink === "hero" ? "#4aa5f0" : "#fff",
//             }}
//           >
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               <img
//                 src="assets/img/whiz-cyber-logo.png"
//                 alt="Logo"
//                 width={100}
//               />
//             </Box>
//           </ScrollLink>
//         </Box>

//         <Box
//           sx={{
//             display: { xs: "none", md: "flex" },
//             gap: 3,
//             alignItems: "center",
//             height: "70px",
//           }}
//         >
//           {[
//             { link: "hero", text: "Home" },
//             { link: "about", text: "About" },
//             { link: "services", text: "Services" },
//             { link: "faq", text: "FAQ" },
//             { link: "contact", text: "Contact" },
//           ].map(({ link, text }) => (
//             <ScrollLink
//               key={link}
//               to={link}
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={500}
//               onClick={() => handleLinkClick(link)}
//               style={{
//                 fontSize: 16,
//                 textDecoration: "none",
//                 cursor: "pointer",
//                 color: activeLink === link ? "#4aa5f0" : "#fff",
//               }}
//             >
//               {text}
//             </ScrollLink>
//           ))}
//           <RouterLink to="/login" style={{ textDecoration: "none" }}>
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{
//                 fontSize: 16,
//                 background: "#4aa5f0",
//                 borderRadius: "50px",
//                 textTransform: "none",
//                 padding: "7px 40px",
//               }}
//             >
//               login
//             </Button>
//           </RouterLink>
//         </Box>

//         <IconButton
//           edge="end"
//           color="inherit"
//           aria-label="menu"
//           onClick={toggleMobileMenu}
//           sx={{ display: { xs: "block", md: "none" } }}
//         >
//           <MenuIcon />
//         </IconButton>
//       </Toolbar>

//       <Drawer
//         anchor="right"
//         open={mobileMenuOpen}
//         onClose={toggleMobileMenu}
//         sx={{
//           display: { md: "none" },
//           "& .MuiDrawer-paper": {
//             backgroundColor: "#171821",
//             color: "#fff",
//           },
//         }}
//       >
//         <List sx={{ width: 250 }}>
//           {[
//             { link: "hero", text: "Home" },
//             { link: "about", text: "About" },
//             { link: "services", text: "Services" },
//             { link: "faq", text: "FAQ" },
//             { link: "contact", text: "Contact" },
//           ].map(({ link, text }) => (
//             <ListItem
//               button
//               key={link}
//               onClick={() => handleLinkClick(link)}
//               sx={{
//                 textTransform: "capitalize",
//                 "&:hover": { backgroundColor: "#222831" },
//               }}
//             >
//               <ListItemText
//                 primary={text}
//                 primaryTypographyProps={{
//                   color: activeLink === link ? "primary" : "inherit",
//                 }}
//               />
//             </ListItem>
//           ))}
//           <ListItem>
//             <RouterLink
//               to="/login"
//               style={{
//                 textDecoration: "none",
//                 width: "100%",
//               }}
//             >
//               <Button
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 sx={{
//                   fontSize: 16,
//                   backgroundColor: "#4aa5f0",
//                   borderRadius: "50px",
//                   textTransform: "none",
//                   "&:hover": {
//                     backgroundColor: "#006bb3",
//                   },
//                 }}
//               >
//                 login
//               </Button>
//             </RouterLink>
//           </ListItem>
//         </List>
//       </Drawer>
//     </AppBar>
//   );
// }

// export default Header;

import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as ScrollLink, scroller, Events } from "react-scroll"; // Import Events from react-scroll
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import theme from "../theme";

function Header() {
  const { user, loading } = useAuth();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("hero"); // Default to the first link

  // Function to handle link click
  const handleLinkClick = (link) => {
    setActiveLink(link);
    setMobileMenuOpen(false); // Close mobile menu
    scroller.scrollTo(link, {
      duration: 500,
      smooth: true,
      offset: -70, // Adjust for fixed header height
    });
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // Track active section based on scrolling
  useEffect(() => {
    const handleScroll = () => {
      const sections = ["hero", "about", "services", "faq", "contact"];
      const scrollPosition = window.scrollY + 75; // Adjust for header height

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const offsetTop = element.offsetTop;
          const offsetHeight = element.offsetHeight;

          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            setActiveLink(section);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.background.default,
        boxShadow: "none",
      }}
    >
      <Toolbar className="7">
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <ScrollLink
            to="hero"
            spy={true}
            smooth={true}
            offset={-70} // Adjust for fixed header height
            duration={500}
            onClick={() => handleLinkClick("hero")}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: activeLink === "hero" ? "#4aa5f0" : "#fff",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src="assets/img/whiz-cyber-logo.png"
                alt="Logo"
                width={100}
              />
            </Box>
          </ScrollLink>
        </Box>

        {/* Desktop Links */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 3,
            alignItems: "center",
            height: "70px",
          }}
        >
          {[
            { link: "hero", text: "Home" },
            { link: "about", text: "About" },
            { link: "services", text: "Services" },
            { link: "faq", text: "FAQ" },
            { link: "contact", text: "Contact" },
          ].map(({ link, text }) => (
            <ScrollLink
              key={link}
              to={link}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => handleLinkClick(link)}
              style={{
                fontSize: 18,
                fontWeight: "400",
                fontFamily: "Roboto, sans-serif",
                textDecoration: "none",
                cursor: "pointer",
                color: activeLink === link ? "#4aa5f0" : "#fff",
              }}
            >
              {text}
            </ScrollLink>
          ))}
          {user ? (
            <RouterLink to="/dashboard" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: 16,
                  borderRadius: "50px",
                  textTransform: "none",
                  padding: "7px 40px",
                  cursor: "pointer",
                  background: "#4aa5f0",
                  fontWeight: "400",
                  fontFamily: "Roboto, sans-serif",
                  "&:hover": {
                    background: "#4aa5f0", // Darker hover color
                  },
                }}
              >
                Dashboard
              </Button>
            </RouterLink>
          ) : (
            <RouterLink to="/login" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: 16,
                  borderRadius: "50px",
                  textTransform: "none",
                  padding: "7px 40px",
                  cursor: "pointer",
                  background: "#4aa5f0",
                  "&:hover": {
                    background: "#4aa5f0", // Darker hover color
                  },
                }}
              >
                Login
              </Button>
            </RouterLink>
          )}
        </Box>
        {/* Mobile Menu Icon */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleMobileMenu}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={toggleMobileMenu}
        sx={{
          display: { md: "none" },
          "& .MuiDrawer-paper": {
            // backgroundColor: "#171821", // Set background color
            backgroundColor: theme.palette.background.default,
            color: "#fff", // Ensure text contrast
          },
        }}
      >
        <List sx={{ width: 250 }}>
          {[
            { link: "hero", text: "Home" },
            { link: "about", text: "About" },
            { link: "services", text: "Services" },
            { link: "faq", text: "FAQ" },
            { link: "contact", text: "Contact" },
          ].map(({ link, text }) => (
            <ListItem
              button
              key={link}
              onClick={() => handleLinkClick(link)}
              sx={{
                fontSize: 18,
                fontWeight: "400",
                fontFamily: "Roboto, sans-serif",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#222831" },
              }}
            >
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  color: activeLink === link ? "#4aa5f0" : "inherit",
                }}
              />
            </ListItem>
          ))}
          <ListItem>
            <RouterLink
              to="/login"
              style={{
                textDecoration: "none",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  fontSize: 16,
                  background: theme.palette.primary.default,
                  borderRadius: "50px",
                  textTransform: "none",
                  "&:hover": {
                    background: theme.palette.primary.default,
                  },
                }}
              >
                Login
              </Button>
            </RouterLink>
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
}

export default Header;
