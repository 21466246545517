import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Icon } from "@iconify/react"; // Import Icon from Iconify
import PropTypes from "prop-types";

const Navbar = ({ toggleSidebar, isSmallScreen }) => {
  // console.log("toggleSidebar", toggleSidebar);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate(); // Initialize the navigate function from react-router-dom

  // Handle Menu Open/Close
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);

  const { user, loading, logout } = useAuth(); // Get user and logout from AuthContext

  // console.log("user", user);

  const handleLogout = () => {
    logout(); // Call logout function
    handleMenuClose(); // Close the menu
    navigate("/login"); // Redirect to the login page
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#171821",
        boxShadow: "none",
        width: isSmallScreen ? "100%" : `calc(100% - 240px)`,
        marginLeft: isSmallScreen ? 0 : "240px",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Toggle Sidebar Icon */}
        {isSmallScreen && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={toggleSidebar}
            sx={{ mr: 2, background: "transparent" }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* Title - Hidden on Mobile */}
        {!isSmallScreen && (
          <Typography variant="h6" sx={{ flexGrow: 1, color: "#fff" }}>
            {/* Dashboard */}
          </Typography>
        )}

        {/* User Profile Dropdown */}
        {/* <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}> */}
        {/* {!isSmallScreen && ( */}
        {/* <Typography variant="body1" sx={{ marginRight: 1 }}> */}
        {/* {loading
              ? "Loading..."
              : user?.firstName && user?.lastName
              ? `${user.firstName} ${user.lastName}`
              : "No user data available"}
          </Typography> */}
        {/* )} */}
        {/* <IconButton onClick={handleMenuOpen}>
            <Avatar src="assets/img/default-avatar.png" alt="John Doe" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Link
              to="/profile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            </Link>
            <Link
              to="/settings"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box> */}

        {/* User Profile Dropdown */}
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <Typography variant="body1" sx={{ marginRight: 1 }}>
            {loading
              ? "Loading..."
              : user?.firstName && user?.lastName
              ? `${user.firstName} ${user.lastName}`
              : "No user data available"}
          </Typography>
          <IconButton onClick={handleMenuOpen}>
            <Avatar src="assets/img/default-avatar.png" alt="John Doe" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Link
              to="/profile"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Icon
                  icon="mdi:account"
                  width={20}
                  height={20}
                  color="#000"
                  style={{ marginRight: 8 }}
                />
                Profile
              </MenuItem>
            </Link>
            <Link
              to="/settings"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Icon
                  icon="mdi:cog"
                  width={20}
                  height={20}
                  color="#000"
                  style={{ marginRight: 8 }}
                />
                Settings
              </MenuItem>
            </Link>
            <MenuItem onClick={handleLogout}>
              <Icon
                icon="mdi:logout"
                width={20}
                height={20}
                color="#000"
                style={{ marginRight: 8 }}
              />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default Navbar;
